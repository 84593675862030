import React from 'react';
import { styled, Subheader, SmallText as BaseSmallText, Grid } from './UIComponents';

const Container = styled('div')({
  backgroundColor: '#FFF',
  boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.1)',
  minHeight: '459px',
  margin: '50px auto',
  maxWidth: '1200px',
  padding: '20px',
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
});

const SmallText = styled(BaseSmallText)(({margin}) => ({
  margin: margin || '30px 0',
  textAlign: 'center',
}));

const BlueText = styled('div')(({theme}) => ({
  fontWeight: 500,
  color: '#0087FF',
  fontSize: '20px',
  lineHeight: '28px',
  textTransform: 'uppercase',

  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  }
}));

const Operator = styled('div')({
  fontSize: '30px',
});

const AlignedGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const BillingCalculation = () => (
  <Container>
    <Subheader>Calculate your overall electricity usage</Subheader>
    <SmallText>
      When you joined the Sunrun community, you added a new, clean source of energy to your home.
      You will always use your solar power first, and you can calculate your total usage with 
      this simple equation:
    </SmallText>
    <Grid container style={{width: '90%'}}>
      <AlignedGrid item sm={3} xs={12}>
        <BlueText>Your Total Electricity Usage</BlueText>
      </AlignedGrid>
      <AlignedGrid item sm={1} xs={12}>
        <Operator>=</Operator>
      </AlignedGrid>
      <AlignedGrid item sm={4} xs={12}>
        <BlueText>Solar Production</BlueText>
        <SmallText margin="0">(Found in mySunrun.com)</SmallText>
      </AlignedGrid>
      <AlignedGrid item sm={1} xs={12}>
        <Operator>+</Operator>
      </AlignedGrid>
      <AlignedGrid item sm={3} xs={12}>
        <BlueText>Utility Electricity Used</BlueText>
      </AlignedGrid>
    </Grid>
  </Container>
);

export default BillingCalculation;
