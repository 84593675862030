import React from 'react';
import { styled } from '@material-ui/styles';
import { NavBarBase } from '../shared/PanelLayout';


const Title = styled('div')({
  fontSize: '25px',
  lineHeight: '25px',
  marginLeft: '5%',
  fontWeight: '500',
  fontFamily: 'sunrunSerif',
  paddingRight: '1rem',
  whiteSpace: 'nowrap',
});

const TabInner = styled('div')({
  borderLeft: '1px solid rgba(0, 26, 60, .25)',
  padding: '0.5rem 1rem',
  whiteSpace: 'nowrap',
  fontSize: '16px',
  lineHeight: '20px',
});

const TabContainer = styled('a')(({selected, theme}) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: selected ? '#115DFB' : theme.colors.lightBlue,

  '&:hover': {
    backgroundColor: '#115DFB',
  },

  [theme.breakpoints.down('xs')]: {
    display: selected ? 'flex' : 'none',
    backgroundColor: '#FFF',
    borderLeft: '1px solid rgba(0, 0, 0, .25)',
  }
}));

const Tab = ({children, selected, onClick}) => (
  <TabContainer selected={selected ? 1 : 0} onClick={onClick}>
    <TabInner>{children}</TabInner>
  </TabContainer>
);

export default ({title, tabs = [], selected, onTabClick}) => (
  <NavBarBase>
    <Title>{title}</Title>
    {tabs.map((tab, index) => (
      <Tab
        key={`${index}-${index === selected}`}
        onClick={() => onTabClick && onTabClick(index)}
        selected={index === selected}
      >{tab}</Tab>
    ))}
  </NavBarBase>
);