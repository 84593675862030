export default [
  {
    quote: 'I was kept informed throughout the full process, things were explained clearly, responses to my questions were timely, and everything kept on schedule.',
    author: 'Jill',
    location: 'New Mexico',
  },
  {
    quote: 'From start to finish Sunrun people did what they said they would, when they said they would, and kept us informed every step of the way. Great customer service.',
    author: 'John',
    location: 'CA',
  },
  {
    quote: 'Everything Sunrun said they would do for us, they did',
    author: 'Daniel',
    location: 'TX',
  },
  {
    quote: 'Everyone we dealt with was knowledgeable, helpful and very friendly. We were kept informed all the way through',
    author: 'Jacquelyn',
    location: 'CA',
  }
];
