import React from 'react';
import { styled } from '@material-ui/styles';

const Container = styled('div')(({active}) => ({
  padding: '0 5%',
  minHeight: '165px',
  width: '100%',
  transition: 'opacity 0.25s ease-out 0.1s',
  ...!active && {
    opacity: 0,
    transition: 'opacity 0.25s ease-in',
  },
}));

const Quote = styled('div')(({theme}) => ({
  fontSize: '30px',
  lineHeight: '38px',
  color: '#FFF',

  [theme.breakpoints.down('xs')]: {
    fontSize: '20px',
    lineHeight: '28px',
  }
}));

const Reviewer = styled('div')({
  fontSize: '16px',
  lineHeight: '30px',
  color: '#FFD45C',
  marginTop: '20px',
});

const Testimonial = ({quote, author, location, active}) => (
  <Container active={active ? 1 : 0}>
    <Quote>{quote}</Quote>
    <Reviewer>{author}, {location}</Reviewer>
  </Container>
);

export default Testimonial;