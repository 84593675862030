import React, { forwardRef } from 'react';
import { Box } from '@material-ui/core';
import { Grid, Hidden, PresentationContainer, Header, OrderableGrid } from './UIComponents'
import { styled } from '@material-ui/styles';
import CircleGrid from '../shared/CircleGrid';
import BulletPoint from './BulletPoint';
import { Grid as GridIcon } from '../shared/Icons';

const PowerOutageImage = styled('div')({
  backgroundImage: `url(/power-outage.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  minHeight: '230px',
});

const Container = styled('div')({
  position: 'relative',
});

const Subheader = styled('div')({
  fontSize: '40px',
  lineHeight: '62px',
});

const Subtext = styled('div')({
  fontSize: '23px',
  lineHeight: '36px',
});

const BlueHeader = styled('div')(({theme}) => ({
  color: theme.colors.lightBlue,
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '20px',
}));

const CircleOverlay = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  width: '24%',
  marginLeft: '38%',
  height: '100%',
});

const InAnOutage = () => ( <>
  <Box m={4}>
    <Grid container spacing={4} alignContent="space-around">
        <Grid item sm={8}>
          <Subheader>
            In an outage, your battery is designed to last through the night and 
            recharge in the morning.
          </Subheader>
        </Grid>
        <Grid item sm={4}>
          <Subtext>
            To make sure your power lasts until the sun comes back, you 
            should probably pay attention to a couple of things:
          </Subtext>
        </Grid>
      </Grid>
    </Box>
    <Box mb={8}>
      <Grid container>
        <Grid item sm xs={12}>
          <BulletPoint mx={4} mb={2} number="1" header="What's backed up during a power outage?">
            What parts of your home did you choose to backup during installation, 
            and how hungry are they for energy? The more energy they use, the 
            faster they empty your battery.
          </BulletPoint>
        </Grid>
        <Grid item sm xs={12}>
          <BulletPoint mx={4} mb={2} number="2" header="How long until morning?">
            For a typical setup, we expect your battery to last you between 8-12 hours, 
            when fully charged. If that won't be long enough, try cutting back on how 
            much power you're using to buy some extra time.
          </BulletPoint>
        </Grid>
        <Grid item sm xs={12}>
          <BulletPoint mx={4} mb={2} number="3" header="What happens during a daytime outage?">
            Your panels will continue to produce energy for you to use and store. 
            Power will be limited to the breakers you chose to backup during installation.
          </BulletPoint>
        </Grid>
      </Grid>
  </Box> </>
);

const YouStillHavePower = () => (
  <Container>
    <Grid container>
      <OrderableGrid item sm xs={12} xsorder="2">
        <PresentationContainer>
          <Grid container>
            <Grid item xs={12} sm={9}>
              <GridIcon />
              <BlueHeader>In A Power Outage</BlueHeader>
              <Header>
                You still have power!
              </Header>
              <div style={{marginTop: '1.5rem', fontSize: '23px', lineHeight: '34px'}}>
                Your neighbors' lights may go out, but yours won't thanks to 
                your Brightbox battery backup.
              </div>
            </Grid>
          </Grid>
        </PresentationContainer>
      </OrderableGrid>
      <OrderableGrid item sm xs={12} xsorder="1">
        <PowerOutageImage />
      </OrderableGrid>
    </Grid>
    <Hidden xsDown>
      <CircleOverlay>
        <CircleGrid rows={10} cols={4} />
      </CircleOverlay>
    </Hidden>
  </Container>
)

export default forwardRef((props, ref) => {
  return (
    <div ref={ref}>    
      <YouStillHavePower />
      <InAnOutage />
    </div>
  );
});
