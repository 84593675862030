import React from 'react';
import BaseLogin from '../shared/Login';

const Header = () => (
  <div>
    Log in to <span style={{whiteSpace: 'nowrap'}}>My Sunrun</span> today
  </div>
)

const Login = () => (
  <BaseLogin header={<Header />} imageSrc="/customer-owned/father-daughter.png" imageAlt="Father and daughter using a laptop computer">
    Monitor your solar energy production, find helpful information, and more.
  </BaseLogin>
);

export default Login;
