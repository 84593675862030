import React, { forwardRef } from 'react';
import { Grid, Hidden, Header } from './UIComponents';
import { styled } from '@material-ui/styles';
import { ClearDay, CloudyDay, RainyDay } from '../shared/Icons';

const WeatherGridImage = styled(Grid)({
  backgroundImage: 'url(/weather.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'left',
  minHeight: '150px',
});

const WeatherContainer = styled('div')({
  
  margin: '10%',
  '& h1': {
    marginTop: 0,
    paddingTop: 0,
  },
  '& div': {
    color: '#0088FF',
    fontWeight: 500,
  }
});

const DetailsContainer = styled('div')( props => ({
  color: '#FFF',
  backgroundColor: props.backgroundcolor,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  '& *': {
    marginLeft: '10%',
    marginRight: '10%',
  },
  '& p': {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '0',
  },
  '& p:last-child': {
    marginBottom: '15%',
  },
  '& h2': {
    marginTop: '5%',
    fontSize: '31px',
    lineHeight: '34px',
    fontWeight: '400',
  },
  '& img': {
    width: '70px',
    marginTop: '10%',
  },
  '& p.subtext': {
    fontStyle: 'italic',
    fontSize: '14px',
    lineHeight: '1',
    marginBottom: '20px',
  }
}));

const WeatherText = ({padding}) => (
  <WeatherContainer style={{padding}}>
    <div style={{fontSize: '20px', lineHeight: '20px'}}>Weather</div>
    <Header color="#000">More light equals more power.</Header>
  </WeatherContainer>
);

export default forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <WeatherGridImage container>
        <Grid item sm={6} />
        <Grid item sm={6}>
          <Hidden xsDown>
            <WeatherText padding="100px 50px" />
          </Hidden>
        </Grid>
      </WeatherGridImage>
      <Hidden smUp>
        <Grid container>
          <Grid item xs>
            <WeatherText />
          </Grid>
        </Grid>
      </Hidden>
      <Grid container>
        <Grid item sm>
          <DetailsContainer backgroundcolor="#115DFB">
            <ClearDay />
            <h2>On a Clear Day</h2>
            <p>Your system thrives.</p>
            <p>Your panels are making the most of a beautiful day by producing up to 100% of their potential.</p>
          </DetailsContainer>
        </Grid>
        <Grid item sm>
          <DetailsContainer backgroundcolor="#021A3E">
            <CloudyDay />
            <h2>On a Cloudy Day</h2>
            <p>Your system continues to produce energy.</p>
            <p>Solar panels can still produce 10-25% of their typical output on a cloudy day.</p>
          </DetailsContainer>
        </Grid>
        <Grid item sm >
        <DetailsContainer backgroundcolor="#41546B">
            <RainyDay />
            <h2>On a Rainy Day</h2>
            <p>Your System still produces a little bit of energy.</p>
            <p>Rain clouds block a lot of light, but you can still expect around 10% of your typical production.</p>
            <p className="subtext">
              Silver lining: Rain provides your panels with a cleaning. Just a few rainy days a year is enough to clean your panels.
            </p>
          </DetailsContainer>
        </Grid>
      </Grid>
    </div>
  )
});