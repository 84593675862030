import {styled} from '@material-ui/styles';
import { default as BaseButton } from '@material-ui/core/Button';
import { default as BaseGrid} from '@material-ui/core/Grid';

export { default as Hidden } from '@material-ui/core/Hidden';
export { default as Box } from '@material-ui/core/Box';
export { default as NavBar } from '../shared/NavBar';

export const OrderableGrid = styled(BaseGrid)(({theme, xsorder}) =>  ({
  [theme.breakpoints.down('xs')]: {
    order: xsorder || 0,
  }
}));

export const Grid = BaseGrid;

export const Button = styled(BaseButton)({
  boxShadow: 'none',
  minHeight: '48px',
  whiteSpace: 'nowrap',
  borderRadius: 0,
  borderWidth: '2px',
});

export const Header = styled('h1')(({color, theme}) => ({
  color: color || '#FFF',
  fontSize: '70px',
  lineHeight: '77px',
  fontFamily: 'sunrunSerif, serif',
  fontWeight: 'lighter',
  margin: 0,

  [theme.breakpoints.down('xs')]: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  }
}));

export const PresentationContainer = styled('div')(({theme}) => ({
  backgroundColor: '#001A3C',
  height: '100%',
  display: 'flex',
  boxSizing: "border-box",
  color: '#FFF',
  minHeight: '768px',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '25px',
  lineHeight: '35px',
  padding: '75px',

  [theme.breakpoints.down('xs')]: {
    padding: '40px',
    minHeight: '320px',
  }
}));

export const Bullet = styled('div')(({theme}) => ({
  height: '30px',
  width: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.lightBlue,
  color: '#FFF',
}));

export const Centered = styled('div')({
  textAlign: 'center',
});
