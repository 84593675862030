import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, Hidden } from './UiComponents';
import { Headset, People, Truck } from '../shared/Icons';

const Container = styled(Grid)(({theme}) => ({
  minHeight: '500px',
  padding: '100px 0',

  [theme.breakpoints.down('xs')]: {
    padding: '50px 0',
  }
}));

const StepGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StepContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '76%',
  minHeight: '100%',

  '& h2': {
    fontSize: '30px',
    color: '#272727',
    lineHeight: '46px',
    marginTop: '40px',
    marginBottom: '25px',
  },

  '& div': {
    fontColor: '#001A3C',
    fontSize: '20px',
    lineHeight: '30px',
  },

  [theme.breakpoints.down('xs')]: {
    textAlign: 'center',

    '& img': {
      margin: 'auto',
    },

    '& img.arrow': {
      margin: '40px auto',
    }
  }
}));

const StepIconContainer = styled('div')({
  display: 'flex',
  width: '100%',
  
  '& img.arrow': {
    margin: 'auto',
    maxWidth: '85%',
    marginLeft: '5px',
  }
})

const Step = ({icon, header, children, hasArrow}) => (
  <StepGrid item xs={12} sm>
    <StepContainer>
      <StepIconContainer>
        {icon}
        {hasArrow && (
          <Hidden xsDown>
            <img className="arrow" src="/service/right-arrow.png" alt="Right arrow" />
          </Hidden>
        )}
      </StepIconContainer>
      <h2>{header}</h2>
      <div>{children}</div>
      {hasArrow && (
        <Hidden smUp>
          <img className="arrow" src="/service/down-arrow.png" alt="Down arrow" />
        </Hidden>
      )}
    </StepContainer>
  </StepGrid>
);

export default () => (
  <Container container>
    <Step icon={<Headset />} header="Call Customer Care." hasArrow="hasArrow">
      Tell us what's going on, and we'll get to the bottom of it.
    </Step>
    <Step icon={<People />} header="We try to fix it remotely." hasArrow="hasArrow">
      If we can't, we'll schedule a visit with our Field Service team.
    </Step>
    <Step icon={<Truck />} header="We fix it in person.">
      One of our local Field Service Technicians will visit your home to
      futher troubleshoot and fix the issue.
    </Step>
  </Container>
);