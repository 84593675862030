import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Facebook, Twitter, Instagram, Youtube } from '../shared/Icons';
import { styled } from '@material-ui/styles';

const Container = styled(Grid)({
  backgroundColor: '#001A3C',
  color: '#FFF',
});

const Logo = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    margin: '10%',
  }
})

const SocialLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '5px',
  border: '1px solid #FFF',
  borderRadius: '100%',
  height: '26px',
  width: '26px',
  cursor: 'pointer',
});

const LinkContainer = styled('div')({
  display: 'flex',
})

const Text = styled(Grid)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',

  [theme.breakpoints.down('xs')]: {
    alignItems: 'center',
    paddingBottom: '30px',
  },

  '& div': {
    fontSize: '12px',
    lineHeight: '18px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '10%',
    },
  },

  '& a': {
    color: '#FFF',
    textDecoration: 'none',
  }
}));

export default () => (
  <Container container justify="space-evenly">
    <Logo item xs={12} sm={4}>
      <img src="/logo-footer.svg" alt="Sunrun logo" />
    </Logo>
    <Text item xs={12} sm={8}>
      <LinkContainer>
        <SocialLink target="_blank" href="https://www.youtube.com/user/SunRunHomeSolar/?mkt_tok=eyJpIjoiTXpZMk1XVXpPREUxTmpFeiIsInQiOiJaM09kZEpGaW5XOGp5TmdRbUpWTWZTSSt5WVJaNXkyQkZvU0NBTGZCRlB5Q1wvVzdxelVmbHRKTjBhVXpuc0ZLc25rNk1tTmFQeTFuRzBibEJueXdmS0E9PSJ9"><Facebook /></SocialLink>
        <SocialLink target="_blank" href="https://twitter.com/Sunrun/?mkt_tok=eyJpIjoiTXpZMk1XVXpPREUxTmpFeiIsInQiOiJaM09kZEpGaW5XOGp5TmdRbUpWTWZTSSt5WVJaNXkyQkZvU0NBTGZCRlB5Q1wvVzdxelVmbHRKTjBhVXpuc0ZLc25rNk1tTmFQeTFuRzBibEJueXdmS0E9PSJ9"><Twitter /></SocialLink>
        <SocialLink target="_blank" href="https://www.instagram.com/sunrunsolar/?mkt_tok=eyJpIjoiTXpZMk1XVXpPREUxTmpFeiIsInQiOiJaM09kZEpGaW5XOGp5TmdRbUpWTWZTSSt5WVJaNXkyQkZvU0NBTGZCRlB5Q1wvVzdxelVmbHRKTjBhVXpuc0ZLc25rNk1tTmFQeTFuRzBibEJueXdmS0E9PSJ9"><Instagram /></SocialLink>
        <SocialLink target="_blank" href="https://www.youtube.com/user/SunRunHomeSolar/?mkt_tok=eyJpIjoiTXpZMk1XVXpPREUxTmpFeiIsInQiOiJaM09kZEpGaW5XOGp5TmdRbUpWTWZTSSt5WVJaNXkyQkZvU0NBTGZCRlB5Q1wvVzdxelVmbHRKTjBhVXpuc0ZLc25rNk1tTmFQeTFuRzBibEJueXdmS0E9PSJ9"><Youtube /></SocialLink>
      </LinkContainer>
      <div>225 Bush Street, Suite 1400, San Francisco, CA 94104</div>
      <div>
        <a href="#temp">State Contractor License Information</a> | 
        &nbsp;<a href="#temp">Privacy Policy</a>
      </div>
    </Text>
  </Container>
);