import React, { forwardRef } from 'react';
import { Box, Grid, Header } from './UIComponents';
import { styled } from '@material-ui/styles';
import { Spring, Summer, Fall, Winter } from '../shared/Icons';

const SeasonGrid = styled(Grid)(({theme}) => ({
  backgroundImage: 'url(/four-seasons.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'top',
  minHeight: '620px',
  marginTop: '-100px',
  alignItems: 'flex-end',

  [theme.breakpoints.down('xs')]: {
    paddingTop: '60px',
    marginTop: '0',
  },
}));

const Season = styled('div')(({theme}) => ({
  display: 'flex',
  height: '300px',
  width: '250px',
  margin: 'auto',
  textAlign: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: 20,
  justifyContent: 'space-between',
  marginBottom: '30px',

  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },

  '& p':{
    fontSize: '20px',
    lineHeight: '24px',
  },
  '& h3': {
    fontSize: '28px',
    lineHeight: '20px',
  },
  '& div:first-child': {
    fontSize: '18px',
    lineHeight: '24px',
  },
  '& div:last-child': {
    fontFamily: 'sunrunSerif',
    fontSize: '21px',
    lineHeight: '27px',
    marginTop: 10,
    fontWeight: '500',
  },
  '& *': {
    margin: '0 10%',
  }
}));

export default forwardRef((props, ref) => {
  return (
    <Box style={{minHeight: '768px'}} m={4} ref={ref}>
      <Grid container>
        <Grid item xs={12}>
          <Header color="#000">How the changing seasons affect solar.</Header>
        </Grid>
        <Grid item xs={12} sm={5}>
          <p style={{fontSize: '20px', lineHeight: '30px'}}>
            We designed your system with all four seasons in mind. <br />
            We expect you to use more power in the summer and less in the winter, 
            and thankfully, solar production peaks and dips at exactly the same times.
          </p>
        </Grid>
      </Grid>

      <SeasonGrid container alignItems="center">
        <Grid item xs={12} sm={3} lg={5}>
          <Season style={{width: '280px'}}>
            <Spring />
            <h3>Spring</h3>
            <div>The sun is getting higher in the sky and each day is longer than the day before.</div>
            <div>Solar production is on the rise</div>
          </Season>
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <Season>
            <Summer />
            <h3>Summer</h3>
            <div>The sun is high, the days are long.</div>
            <div>Solar production is peaking</div>
          </Season>
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Season>
            <Fall />
            <h3>Fall</h3>
            <div>The sun is retreating and each day is a little shorter than the last.</div>
            <div>Solar production is falling</div>
          </Season>
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Season>
            <Winter />
            <h3>Winter</h3>
            <div>The sun is low and the days are short.</div>
            <div>Solar production is limited</div>
          </Season>
        </Grid>
      </SeasonGrid>
    </Box>
  );
});