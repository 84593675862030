import React, { useState } from 'react';
import { styled } from '@material-ui/styles';
import { Hidden } from './UIComponents';
import { Link, useLocation } from 'react-router-dom';

const Header = styled('div')({
  width: '100%',
  height: '130px',
  backgroundColor: '#0087FF',
  color: '#FFF',
  fontSize: '30px',
  lineHeight: '38px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& h3': {
    fontSize: '30px',
    lineHeight: '38px',
    margin: '40px',
    fontWeight: '500',
  }
});

const Image = styled('div')(({src}) => ({
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '290px',
  position: 'relative',
}));

const LinkContainer = styled(Link)({
  width: '360px',
  minWidth: '320px',
  display: 'block',
  textDecoration: 'none',
});

const Shadow = styled('div')({
  boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.25)',
})

const OverlayContainer = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
});

const OverlayText = styled('div')({
  fontSize: '20px',
  lineHeight: '28px',
  color: '#FFF',
  margin: '40px',
});

const OverlayLink = styled('div')({
  margin: '0 40px',
  fontSize: '16px',
  lineHeight: '28px',
  textTransform: 'uppercase',
  color: '#FFD35C',
  fontWeight: '500',
});

const MobileLink = styled('div')({
  fontSize: '16px',
  lineHeight: '28px',
  textTransform: 'uppercase',
  color: '#0087FF',
  fontWeight: '500',
});

const MobileText = styled('div')({
  fontSize: '16px',
  lineHeight: '22px',
  height: '70px',
  color: '#272727',
  margin: '30px 0',
});

const Overlay = ({children}) => (
  <Hidden smDown>
    <OverlayContainer>
      <OverlayText>{children}</OverlayText>
      <OverlayLink>Learn More ></OverlayLink>
    </OverlayContainer>
  </Hidden>
);

const MobileContent = ({children}) => (
  <Hidden mdUp>
    <MobileText>{children}</MobileText>
    <MobileLink>Learn More ></MobileLink>
  </Hidden>
);

const AnchorLink = ({to, img, title, children}) => {
  const [hovering, setHovering] = useState(false);
  const queryParams = useLocation().search;
  const destination = to + queryParams;

  return (
    <LinkContainer to={destination} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
      <Shadow>
        <Header><h3>{title}</h3></Header>
        <Image src={img}>
          {hovering && <Overlay>{children}</Overlay>}
        </Image>
      </Shadow>
      <MobileContent>{children}</MobileContent>
    </LinkContainer>
  );
}

export default AnchorLink;