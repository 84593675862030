import React from 'react';
import { styled } from '@material-ui/styles';

const Icon = styled('img')(({style, size}) => ({
  width: size ? `${size}px` : '80px',
  height: size ? `${size}px` : '80px',
  ...style || {},
}));

const SmallIcon = styled('img')(({style}) => ({
  width: '13px',
  height: '13px',
  ...style || {},
}));

export const House = props => <Icon src="/icons/house.png" alt="House Icon" {...props} />
export const SolarHouse = props => <Icon src="/icons/solar-house.png" alt="Solar Powered House" {...props} />
export const Grid = props => <Icon src="/icons/grid.png" alt="Grid Icon" {...props} />
export const Chart = props => <Icon src="/icons/chart.png" alt="Chart Icon" {...props} />
export const ClearDay = props => <Icon src="/icons/clear-day.svg" alt="Clear Day Icon" {...props} />
export const Cloud = props => <Icon src="/icons/cloud.png" alt="Cloud Day Icon" {...props} />
export const CloudyDay = props => <Icon src="/icons/cloudy-day.svg" alt="Cloudy Day Icon" {...props} />
export const Fall = props => <Icon src="/icons/fall.png" alt="Fall Icon" {...props} />
export const Handshake = props => <Icon src="/icons/handshake.png" alt="Handshake Icon" {...props} />
export const Sun = props => <Icon src="/icons/sun-yellow.png" alt="Sun" {...props} />
export const Megaphone = props => <Icon src="/icons/megaphone.png" alt="Megaphone Icon" {...props} />
export const RainyDay = props => <Icon src="/icons/rainy-day.svg" alt="Rainy Day Icon" {...props} />
export const Spring = props => <Icon src="/icons/spring.png" alt="Spring Icon" {...props} />
export const Summer = props => <Icon src="/icons/summer.png" alt="Summer Icon" {...props} />
export const Winter = props => <Icon src="/icons/winter.png" alt="Winter Icon" {...props} />
export const AirConditioner = props => <Icon src="/icons/air-conditioner.svg" alt="Air Conditioner Icon" {...props} />
export const WasherDryer = props => <Icon src="/icons/washer-dryer.svg" alt="Washer and Dryer" {...props} />
export const PoolPump = props => <Icon src="/icons/pool-pump.svg" alt="Pool Pump" {...props} />
export const Dishwasher = props => <Icon src="/icons/dishwasher.svg" alt="Dishwasher" {...props} />
export const Microwave = props => <Icon src="/icons/microwave.svg" alt="Microwave" {...props} />
export const WaterHeater = props => <Icon src="/icons/water-heater.svg" alt="Electric Water Heater" {...props} />
export const Headset = props => <Icon src="/icons/headset.png" alt="Headset" {...props} />
export const People = props => <Icon src="/icons/people.png" alt="People" {...props} />
export const Truck = props => <Icon src="/icons/truck.png" alt="Truck" {...props} />
export const BlueMicrowave = props => <Icon src="/icons/microwave-blue.png" alt="Microwave" {...props} />
export const BlueAdjusters = props => <Icon src="/icons/adjusters-blue.png" alt="Adjusting Switches" {...props} />
export const BlueChart = props => <Icon src="/icons/chart-blue.png" alt="Chart" {...props} />

export const Facebook = props => <SmallIcon src="/icons/facebook.png" alt="Facebook" {...props} />
export const Twitter = props => <SmallIcon src="/icons/twitter.png" alt="Twitter" {...props} />
export const Instagram = props => <SmallIcon src="/icons/instagram.png" alt="Instagram" {...props} />
export const Youtube = props => <SmallIcon src="/icons/youtube.png" alt="Youtube" {...props} />
