import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import { Grid, PresentationContainer, Subheader, styled } from './UIComponents';

const Text = styled('div')({
  marginTop: '40px',
  color: '#272727',
  fontSize: '20px',
  lineHeight: '28px',
});

const FamilyImage = styled('div')({
  backgroundImage: 'url(/savings/family-playing.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  minHeight: '300px',
  width: '100%',
})

const Freedom = () => (
  <>
    <ContentDivider>It's all about all the energy you <i>don't</i> buy.</ContentDivider>
    <Grid container>
      <Grid item md sm={12} xs={12}>
        <FamilyImage />
      </Grid>
      <Grid item md sm={12} xs={12}>
        <PresentationContainer backgroundcolor="#FFF">
          <Subheader align="left">
            Freedom from rate hikes. <br />
            More money in your pocket.
          </Subheader>
          <Text>
            Utility rate increases are unpredictable but when your power comes from your roof instead of the utility, 
            you take back control over what you're paying for your power.
          </Text>
          <Text>
            No matter how much your utility increases its rates, you know exactly how much you'll owe Sunrun each month 
            for the full length of our 25-year agreement.
          </Text>
        </PresentationContainer>
      </Grid>
    </Grid>
  </>
);

export default Freedom;
