import React from 'react';
import {Grid, Hidden, Header, PresentationContainer } from './UIComponents';
import { styled } from '@material-ui/styles';
import { HomeNavBar } from '../shared/PanelLayout';
import useFocusedComponent from '../shared/useFocusedComponent';
import { House } from '../shared/Icons';

const HomeImage = styled('div')({
  backgroundImage: `url(/onboarding/home.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
});

export default ({setNavbar}) => {
  const ref = useFocusedComponent(() => setNavbar(<HomeNavBar />));

  return (
    <Grid container ref={ref}>
      <Grid item xs={12} sm>
        <PresentationContainer>
          <div>
            <House />
            <Header>Your home, <br /> your energy</Header>
            <div style={{marginTop: '20px'}}>Solar is about to change the way you power your life.</div>
            <div style={{fontSize: '20px', margin: '30px 0'}}>Read on to learn what to expect.</div>
          </div>
        </PresentationContainer>
      </Grid>
      <Grid item sm>
        <Hidden xsDown>
          <HomeImage />
        </Hidden>
      </Grid>
    </Grid>
  );
};
