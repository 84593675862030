import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, PresentationContainer, Header as BaseHeader, Button, ScalableImage, OrderableGrid } from './UiComponents';
import { Chart } from '../shared/Icons';


const Header = styled(BaseHeader)({
  wordBreak: 'break-word',
  margin: '30px 0',
});

const TextGrid = styled(OrderableGrid)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%',
  }
}));

const LoginButton = () => (
  <div>
    <Grid container>
      <Grid item xs={12} sm={5}>
        <Button href="https://www.mysunrun.com/#/login" variant="contained" color="secondary" size="large">Log in now</Button>
      </Grid>
    </Grid>
  </div>
);

export default () => (
  <Grid container>
    <TextGrid item xs={12} md={6} smorder="1">
      <PresentationContainer backgroundcolor="#00B085">
        <Chart />
        <Header>
          Log in to mySunrun.com to see how much energy you're producing.
        </Header>
        <LoginButton />
      </PresentationContainer>
    </TextGrid>
    <OrderableGrid item xs={12} md={6}>
      <ScalableImage src="/login.png" alt="Man sitting on a laptop" minheight="340px" />
    </OrderableGrid>
  </Grid>
);
