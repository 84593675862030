import React, { useState, forwardRef } from 'react';
import { styled } from '@material-ui/styles';

const Arc = styled('div')(({arc, theme}) => ({
  overflow: 'hidden',
  backgroundImage: `url(/arcs/tod-arc-${arc}.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center top',
  height: theme.panelHeight,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',

  [theme.breakpoints.down('xs')]: {
    background: 'none',
  }
}));

const ArcContent = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '87%',
  position: 'relative',
  width: '40%',

  [theme.breakpoints.down('xs')]: {
    width: '100%',
    background: 'none',
    '& img': {
      width: '100%',
      height: 'auto',
    },

    '& > div:first-child': {
      width: '90%',
    }
  }
}));

const TopContainer = styled('div')({
  display: 'flex',

  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-around',
  textAlign: 'center',
  height: '40%',
  width: '100%',
});

const BottomContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50%',
});

const Header = styled('div')({
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '20px',
});

const Content = styled('div')({
  fontSize: '30px',
  lineHeight: '34px',
});

const SubContent = styled('div')({
  color: '#A4A4A4',
});

const IconButton = styled('button')({
    background: 'none',
    border: 0,
    cursor: 'pointer',
    margin: '0 5px',

    '&:focus': {
      outline: 0,
    }
});

const ModalLink = styled(IconButton)({
  color: '#00B084',
  pointer: 'cursor',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '15px',
});

const Modal = styled('div')(({open, arc, theme}) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  padding: '20% 30%',
  display: open ? 'flex' : 'none',
  backgroundColor: 'rgba(255, 255, 255, 0.96)',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  backgroundImage: `url(/arcs/tod-arc-${arc}.png)`,


  '& > div': {
    color: '#000',
    margin: '20px',
  },

  '& > button': {
    margin: '20px',
  },

  [theme.breakpoints.down('xs')]: {
    padding: '5% 10%'
  },

  [theme.breakpoints.up('sm')]: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center top',
  },
}));

const Image = styled('img')({
  height: '259px',
  marginBottom: '25px',
});


export default forwardRef(({panels}, ref) => {
  const [currentPanel, setCurrentPanel] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const nextPanel = () => {
    setCurrentPanel((currentPanel + 1) % panels.length);
  }

  const previousPanel = () => {
    if (currentPanel === 0) {
      setCurrentPanel(panels.length - 1);
    } else {
      setCurrentPanel(currentPanel - 1);
    }
  }

  const {header, image, content, subContent, modalLink, modal, arcNumber = currentPanel} = panels[currentPanel];

  return (
    <Arc arc={arcNumber} ref={ref}>
      <ArcContent>
        <TopContainer>
          <Header>{header}</Header>
          <Content>{content}</Content>
          <SubContent>{subContent}</SubContent>
          <ModalLink onClick={() => setModalOpen(true)}>
            {modalLink}
          </ModalLink>
        </TopContainer>
        <BottomContainer>
          <Image src={image} />
          <div>
            <IconButton onClick={previousPanel}>
              <img src="/icons/arrow-left.png" alt="Navigation Arrow Left" />
            </IconButton>
            <IconButton onClick={nextPanel}>
              <img src="/icons/arrow-right.png" alt="Navigation Arrow Right" />
            </IconButton>
          </div>
        </BottomContainer>
      </ArcContent>

      <Modal open={modalOpen} arc={arcNumber}>
        <Content>{modal && modal.header}</Content>
        {modal && modal.content.map((content, i) => (
          <SubContent key={i}>{content}</SubContent>
        ))}
        <IconButton onClick={() => setModalOpen(false)}>
          <img src="/icons/close.png" alt="Close Modal" />
        </IconButton>
      </Modal>
    </Arc>
  );
});
