import { styled } from '@material-ui/styles';
import { default as BaseButton } from '@material-ui/core/Button';
import { default as BaseGrid } from '@material-ui/core/Grid';

export { default as Hidden } from '@material-ui/core/Hidden';

export const FullImage = styled('img')({
  width: '100%',
  height: '100%',
});

export const OrderableGrid = styled(BaseGrid)(({theme, smorder}) =>  ({
  [theme.breakpoints.down('sm')]: {
    order: smorder || 0,
  }
}));

export const Grid = BaseGrid;

export const ScalableImage = styled('div')(({src, minheight = '100%'}) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  width: '100%',
  minHeight: minheight,
}));

export const Button = styled(BaseButton)({
  boxShadow: 'none',
  height: '48px',
  whiteSpace: 'nowrap',
  borderRadius: 0,
  borderWidth: '2px',
});

export const PresentationContainer = styled('div')(({theme, backgroundcolor}) => ({
  backgroundColor: backgroundcolor || '#001A3C',
  display: 'flex',
  boxSizing: "border-box",
  color: '#FFF',
  height: '100%',
  minHeight: '768px',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: '20px',
  lineHeight: '30px',
  padding: '75px',

  [theme.breakpoints.down('xs')]: {
    padding: '40px',
    minHeight: 'auto',
  }
}));

export const Header = styled('h1')(({color, theme}) => ({
  color: color || '#FFF',
  fontSize: '70px',
  lineHeight: '75px',
  fontFamily: 'sunrunSerif, serif',
  fontWeight: 'lighter',
  margin: 0,

  [theme.breakpoints.down('xs')]: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
  }
}));