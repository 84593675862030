import React, {useState} from 'react';
import PanelLayout from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import Home from './Home';
import Freedom from './Freedom';
import FourSeasons from './FourSeasons';
import Bills from './Bills';
import UseLessSaveMore from './UseLessSaveMore';
import Login from './Login';
import Footer from '../shared/Footer';
import SurplusPower from './SurplusPower';

const SavingsAnchor = () => {
  const [state, setState] = useState('');
  const showLogin = useShowLogin();

  return (
    <PanelLayout>
      <Home state={state} setState={setState} />
      <Freedom />
      <SurplusPower state={state} />
      <FourSeasons state={state} />
      <Bills />
      <UseLessSaveMore />
      {showLogin && <Login />}
      <Footer />
    </PanelLayout>
  );
};

export default SavingsAnchor;
