import React from 'react';
import { styled } from '@material-ui/styles';

const SunrunLogo = styled('img')(({ theme }) => ({
  marginLeft: 100,
  width: '25%',
  maxWidth: 200,

  [theme.breakpoints.down('xs')]: {
    marginLeft: 30,
  },
}));

const MoreInformationContainer = styled('div')((å) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#e7f5fb',
  padding: '20px 50px 20px 50px',
}));

export default () => (
  <MoreInformationContainer>
    <p style={{ fontSize: 20, width: '75%' }}>
      We hope you find these packets helpful. For more information about the
      home sale process, check out{' '}
      <a
        href='https://www.sunrun.com/go-solar-center/solar-faq/what-happens-if-i-move'
        style={{ fontWeight: 500, textDecoration: 'underline' }}
        target='_blank'
        rel='noopener noreferrer'
      >
        What happens if I move?
      </a>{' '}
      on our FAQ page.
    </p>
    <SunrunLogo src='/sell-your-home/sunrun-logo.png' />
  </MoreInformationContainer>
);
