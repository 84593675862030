import React from 'react';
import { styled } from '@material-ui/styles';
import { Header as BaseHeader } from '../Service/UiComponents';

const Header = styled(BaseHeader)({
  textAlign: 'center',
});

const Subtitle = styled('div')({
  fontFamily: 'sunrunSerif, serif',
  color: '#FFF',
  fontSize: '25px',
  lineHeight: '35px',
  marginTop: '20px',
});

const HeaderContainer = styled('div')(({theme}) => ({
  minHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 7%',
  backgroundColor: '#0087FF',

  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    padding: '5% 10%',
  }
}));

export default ({children, subtitle}) => (
  <HeaderContainer>
    <Header>{children}</Header>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </HeaderContainer>
);
