import React from 'react';
import useFocusedComponent from '../shared/useFocusedComponent';
import NavBar from '../shared/NavBar';
import TimeOfDay from './TimeOfDay';
import PowerOutage from './PowerOutage';
import Weather from './Weather';
import TheFourSeasons from './TheFourSeasons';
import timeOfDayData from './timeOfDayData';

export default ({setNavbar, region}) => {
  const components = [
    {name: 'Time of Day', component: TimeOfDay},
    ...(region !== 'hawaii' ? [{name: 'Power Outage', component: PowerOutage}] : []),
    {name: 'Weather', component: Weather},
    {name: 'The Four Seasons', component: TheFourSeasons},
  ];

  const panels = timeOfDayData[region];

  const SystemBehaviorNavBar = ({selected, onTabClick}) => (
    <NavBar 
      title="System Behavior" 
      tabs={components.map(({name}) => name)} 
      selected={selected}
      onTabClick={onTabClick}
    />
  );


  const onTabClick = tabIndex => {
    window.scrollTo(0, refs[tabIndex].current.offsetTop - 60);
  };

  const refs = components.map((_, i) => (
    useFocusedComponent(() => setNavbar(
      <SystemBehaviorNavBar selected={i} onTabClick={onTabClick} />
    ))
  ));

  return (
    <>
      {components.map(({component: ComponentName}, i) => (
        <ComponentName key={i} ref={refs[i]} region={region} panels={panels} />
      ))}
    </>
  );
};
