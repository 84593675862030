import React from 'react';
import { Grid, Header, Hidden } from './UIComponents';
import { styled } from '@material-ui/styles';
import { AirConditioner, WasherDryer, PoolPump, Dishwasher, Microwave, WaterHeater } from '../shared/Icons';

const Container = styled('div')({
  backgroundColor: '#F7F7F7',
  display: 'flex',
  paddingTop: '2rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& h1': {
    textAlign: 'center',
  }
});

const ApplianceIcon = styled(Grid)(({highlighted, theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'center',
  marginBottom: '20px',
  alignItems: 'center',
  position: 'relative',
  order: 1,

  '& img': {
    width: '75px',
    height: '75px',
    marginBottom: '10px',
    zIndex: 1,
  },

  '& div': {
    zIndex: 1,
    fontSize: '20px',
    lineHeight: '30px',
  },

  ...(highlighted ? {
    [theme.breakpoints.down('xs')]: {
      order: 0,
      padding: '0 100px',
    }
  } : {})
}));

const HighlightCircle = styled('div')({
  backgroundColor: '#DCE2E7',
  borderRadius: '100%',
  height: '271px',
  top: '-45px',
  width: '280px',
  position: 'absolute',
  zIndex: 0,
});

export default () => (
  <Container>
    <Header color="#000">Hungriest Appliances</Header>
    <Grid container justify="space-evenly" style={{margin: '10%'}}>
      <ApplianceIcon item xs={6} sm style={{zIndex: 2}}>
        <WasherDryer />
        <div>Washer + Dryer</div>
      </ApplianceIcon>
      <ApplianceIcon item xs={12} sm highlighted="true">
        <Hidden xsDown><HighlightCircle /></Hidden>
        <AirConditioner />
        <div>Air Conditioning is the leading cause of high utility bills</div>
      </ApplianceIcon>
      <ApplianceIcon item xs={6} sm>
        <PoolPump />
        <div>Pool Pump</div>
      </ApplianceIcon>
      <ApplianceIcon item xs={6} sm>
        <Dishwasher />
        <div>Dishwasher</div>
      </ApplianceIcon>
      <ApplianceIcon item xs={6} sm>
        <Microwave />
        <div>Microwave</div>
      </ApplianceIcon>
      <ApplianceIcon item xs={6} sm>
        <WaterHeater />
        <div>Electric Water Heater</div>
      </ApplianceIcon>
    </Grid>
  </Container>
);
