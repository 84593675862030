import React from 'react';
import { styled } from './UIComponents';

const Container = styled('div')({
  maxWidth: '238px',
  width: '90%',
  height: '48px',
  margin: 'auto',
  borderRadius: '6px',
  color: '#272727',
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  userSelect: 'none',
});

const SurplusButton = ({children, active, color, onClick}) => {
  const backgroundColor = active ? color : '#E7E7E7';

  return (
    <Container style={{backgroundColor}} onClick={onClick}>
      {children}
    </Container>
  );
};

export default SurplusButton;
