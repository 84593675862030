import React from 'react';
import { styled } from '@material-ui/styles';
import { Header, Hidden } from './UiComponents';
import CircleGrid from '../shared/CircleGrid';

const Container = styled('div')(({theme}) => ({
  position: 'relative',
  color: '#FFF',
  backgroundColor: '#001A3C',
  minHeight: '500px',
  fontSize: '25px',
  lineHeight: '35px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

  '& div.content': {
    marginTop: '40px',
  },

  '& div.subContainer': {
    width: '55%',
    margin: '100px 0',

    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginTop: 0,
    },
  }
}));

const CircleContainer = styled('div')(({theme}) => ({
  width: '22%',
  height: '100%',
  position: 'absolute',
  left: 0,
  alignSelf: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: '200px',
    position: 'relative',
  }
}));

const AccentText = styled('span')({
  fontWeight: '500',
  color: '#FFD45C',
});

export default () => (
  <Container>
    <CircleContainer>
      <Hidden smDown>
        <CircleGrid rows={8} cols={4} />
      </Hidden>
      <Hidden mdUp>
        <CircleGrid rows={4} cols={14} xs="xs" />
      </Hidden>
    </CircleContainer>
    <div className="subContainer">
      <Header>Your home. Your energy.<br />Our responsibility.</Header>
      <div className="content">
        We handle the system maintenance for the full length of our agreement.
        <AccentText>That's the Sunrun Guarantee.</AccentText>
      </div>
    </div>
  </Container>
)