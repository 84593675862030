import React from 'react';
import {Grid, Hidden, Button as BaseButton, Header, PresentationContainer } from './UIComponents';
import { styled } from '@material-ui/styles';
import { HomeNavBar } from '../shared/PanelLayout';
import useFocusedComponent from '../shared/useFocusedComponent';
import { House } from '../shared/Icons';

const HomeImage = styled('div')(({region}) => ({
  backgroundImage: `url(/${region}-home.jpg)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
}));

const Button = styled(BaseButton)({
  width: '100%',
  whiteSpace: 'normal',
  paddingLeft: 0,
  paddingRight: 0,
  height: '100%',
})

const RegionButton = ({region, setRegion, slug, children}) => (
  <Grid item xs={12} sm={6} md={3} style={{padding: '5px'}}>
    <Button
      size="large"
      variant={region === slug ? 'contained' : 'outlined'}
      color="primary"
      onClick={() => setRegion(slug)}
    >{children}</Button>
  </Grid>
)

export default ({region, setRegion, setNavbar}) => {
  
  const ref = useFocusedComponent(() => setNavbar(<HomeNavBar />));

  return (
    <Grid container component="main" ref={ref}>
      <Grid item xs={12} sm>
        <PresentationContainer>
          <House />
          <Header>Your home, <br /> your energy</Header>
          <div style={{marginTop: '20px'}}>Solar and storage are about to change the way you power your life.</div>
          <div style={{margin: '30px 0'}}>Pick your location to learn what to expect.</div>
          <Grid container>
            <RegionButton region={region} setRegion={setRegion} slug="eastCoast">East Coast</RegionButton>
            <RegionButton region={region} setRegion={setRegion} slug="westCoast">West Coast</RegionButton>
            <RegionButton region={region} setRegion={setRegion} slug="hawaii">Hawaii</RegionButton>
            <RegionButton region={region} setRegion={setRegion} slug="texas">Texas</RegionButton>
          </Grid>
        </PresentationContainer>
      </Grid>
      <Grid item sm>
        <Hidden xsDown>
          <HomeImage region={region} />
        </Hidden>
      </Grid>
    </Grid>
  );
};
