import React from 'react';
import { Grid, Hidden, NavBar } from './UIComponents';
import { styled } from '@material-ui/styles';
import SystemDesignBullets from './SystemDesignBullets';
import useFocusedComponent from '../shared/useFocusedComponent';

const LeftContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',

  '& img': {
    flexGrow: 1,
    maxWidth: '100%',
  }
})

const ApplianceContainer = styled('div')(({theme}) => ({
  backgroundColor: theme.colors.darkBlue,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 2,

  '& img': {
    maxWidth: '100%',
  }
}));

export default ({setNavbar, region}) => {

  const ref = useFocusedComponent(() => setNavbar(<NavBar title="System Design" />));

  return (
    <Grid container ref={ref}>
      <LeftContainer item xs={12} sm>
        <img src="/cartoon-house.png" alt="A house with solar panels connected to a powerline in the daytime" />
        <ApplianceContainer>
          <Hidden xsDown>
            <img src="/onboarding/appliances.png" alt="Icons to represent the listed appliances" style={{width: '100%'}} />
          </Hidden>
          <Hidden smUp>
            <img src="/onboarding/appliances-mobile.png" alt="Icons to represent the listed appliances" />
          </Hidden>
        </ApplianceContainer>
      </LeftContainer>
        
      <Grid item sm xs={12}>
        <SystemDesignBullets region={region} />
      </Grid>
    </Grid>
  );
};