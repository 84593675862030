import React, { useState, useEffect } from 'react';
import { Grid, SmallText, styled, Hidden } from './UIComponents';
import Button from './SurplusButton';

const Subheader = styled('div')(({theme}) => ({
  color: '#272727',
  fontSize: '30px',
  fontWeight: '500',
  marginBottom: '20px',

  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  }
}));

const SeasonGrid = styled(Grid)(({theme}) => ({
  textAlign: 'center',
  padding: '40px',

  [theme.breakpoints.down('sm')]: {
    padding: '10px',
  }
}));

const GraphImage = styled('img')({
  width: '100%',
});

const Container = styled('div')(({theme}) => ({
  width: '80%',
  margin: 'auto',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  }
}));


const SurplusGraph = ({imageBase, spring, summer, fall, winter}) => {
  const [graphType, setGraphType] = useState('combined');
  const [producedActive, setProducedActive] = useState(true);
  const [boughtActive, setBoughtActive] = useState(true);

  useEffect(() => {
    if (producedActive && ! boughtActive) setGraphType('produced');
    else if (! producedActive && boughtActive) setGraphType('bought');
    else setGraphType('combined');
  }, [producedActive, boughtActive]);

  return (
    <>
      <Grid container>
        <Grid item sm={4} md={6} xs={12}></Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Button color="#F9EDC9" active={producedActive} onClick={() => setProducedActive(! producedActive)}>kWh produced</Button>
        </Grid>
        <Grid item md={3} sm={4} xs={6}>
          <Button color="#C6E1F9" active={boughtActive} onClick={() => setBoughtActive(! boughtActive)}>kWh bought</Button>
        </Grid>
      </Grid>
      <Container>
        <Hidden xsDown>
          <GraphImage src={`${imageBase}-${graphType}.svg`} />
        </Hidden>
        <Hidden smUp>
          <GraphImage src={`${imageBase}-${graphType}-mobile.svg`} />
        </Hidden>
        <Grid container>
          <SeasonGrid item xs>
            <Subheader>Spring</Subheader>
            <Hidden xsDown>
              <SmallText>{spring}</SmallText>
            </Hidden>
          </SeasonGrid>
          <SeasonGrid item xs>
            <Subheader>Summer</Subheader>
            <Hidden xsDown>
              <SmallText>{summer}</SmallText>
            </Hidden>
          </SeasonGrid>
          <SeasonGrid item xs>
            <Subheader>Fall</Subheader>
            <Hidden xsDown>
              <SmallText>{fall}</SmallText>
            </Hidden>
          </SeasonGrid>
          <SeasonGrid item xs>
            <Subheader>Winter</Subheader>
            <Hidden xsDown>
              <SmallText>{winter}</SmallText>
            </Hidden>
          </SeasonGrid>
        </Grid>
      </Container>
    </>
  );
};

export default SurplusGraph;
