import React, { forwardRef } from 'react';
import TimeOfDay from '../IntroToSolar/TimeOfDay';

const panels = [
  {
    header: 'Time of Day',
    content: 'Your solar production rises and falls with the sun.',
    image: '/tod-chart.png',
  },
  {
    arcNumber: 2,
    image: '/onboarding/sunrise.gif',
    header: 'As The Sun Rises',
    content: 'Your solar panels start to produce energy, which gets used immediately.',
    subContent: 'You may still have to buy some additional energy from your utility.',
    modalLink: 'How do I power my mornings?',
    modal: {
      header: 'How do I power my mornings?',
      content: [
        'If you\'re home, your panels probably won\'t be able to produce as much energy as you\'re using. ' +
        'You may need to buy some from the utility. If you\'re not home, there\'s a good chance you will have extra ' +
        'energy to send to the utility in exchange for a credit.',
      ]
    }
  },
  {
    arcNumber: 3,
    header: 'As The Sun Peaks in The Sky',
    image: '/onboarding/midday.gif',
    content: 'You are producing as much solar energy as possible.',
    subContent: 'This likely means you are producing more energy than you can use. Extra energy is sent into the grid in exchange for a billing credit from your utility.',
    modalLink: 'What is a billing credit?',
    modal: {
      header: 'What is a billing credit?',
      content: [
        'Solar power needs to be used or stored immediately, so any time you\'re producing more than you\'re using, the extra power is sent into the grid in exchange for a billing credit from your utility.',
        'These credits will be applied to you rnext utility bill. When your billing credits are bigger than your bill, the extra will rollover to the next month.',
      ]
    },
  },
  {
    arcNumber: 4,
    header: 'As The Sun Sets',
    image: '/onboarding/sunset.gif',
    content: 'Your production declines. Demand soars.',
    subContent: 'Your system produces less energy and you\'ll begin using energy from your utility.',
    modalLink: 'What happens when demand increases?',
    modal: {
      header: 'What happens when demand increases?',
      content: [
        `AS the sun goes down, most people come home from work and start using more energy. They turn on the TV, do some laundry, cook dinner, and more. Some utilities charge more when demand is high. To save money and stretch your billing credits, try to limit your electricity use in the late afternoon and early evening.`
      ]
    }
  },
  {
    arcNumber: 5,
    header: 'As The Sun Sleeps',
    image: '/onboarding/night.gif',
    content: 'Your solar production returns to zero, and all of your energy comes from your utility.',
    modalLink: 'What does my system do at night?',
    modal: {
      header: 'What does my system do at night?',
      content: [
        `At night, your solar system will go into standby mode. Don't worry if the indicator lights are off - that's normal. The system will automatically come back on in the morning.`,
      ]
    }
  }
];

export default forwardRef((props, ref) => <TimeOfDay ref={ref} panels={panels} />);
