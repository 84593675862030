import React, { useState } from 'react';
import PanelLayout, { HomeNavBar } from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import Home from './Home';
import SystemDesign from './SystemDesign';
import SystemBehavior from './SystemBehavior';
import Billing from './Billing';
import Guarantee from './Guarantee';
import Login from './Login';
import Footer from '../shared/Footer';

const IntroToSolar = () => {
  const [navbar, setNavbar] = useState(<HomeNavBar />);
  const [region, setRegion] = useState('eastCoast');
  const showLogin = useShowLogin();

  return (
    <PanelLayout navbar={navbar}>
      <Home setNavbar={setNavbar} region={region} setRegion={setRegion} />
      <SystemDesign setNavbar={setNavbar} region={region} />
      <SystemBehavior setNavbar={setNavbar} key={region} region={region} />
      <Billing setNavbar={setNavbar} region={region} />
      <Guarantee setNavbar={setNavbar} />
      {showLogin && <Login />}
      <Footer />
    </PanelLayout>
  );
}

export default IntroToSolar;
