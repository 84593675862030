import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import HelpSteps from '../Service/HelpSteps';

const IfYouNeedAHand = () => (
  <>
    <ContentDivider>
      If you need a hand
    </ContentDivider>
    <HelpSteps />
  </>
);

export default IfYouNeedAHand;
