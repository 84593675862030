import React, { useState } from 'react';
import PanelLayout, { HomeNavBar } from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import useFocusedComponent from '../shared/useFocusedComponent';
import Home from '../Onboarding/Home';
import SystemDesign from '../Onboarding/SystemDesign';
import SystemBehavior from '../Onboarding/SystemBehavior';
import Billing from './Billing';
import CaringForYourPanels from '../Service/CaringForYourPanels';
import KeepingAnEye from '../Service/KeepingAnEye';
import IfYouNeedAHand from './IfYouNeedAHand';
import Login from './Login';
import Footer from '../shared/Footer';

const CustomerOwned = () => {
  const [navbar, setNavbar] = useState(<HomeNavBar />);
  const ref = useFocusedComponent(() => setNavbar(
    <HomeNavBar />
  ));
  const showLogin = useShowLogin();

  return (
    <PanelLayout navbar={navbar}>
      <Home setNavbar={setNavbar} />
      <SystemDesign setNavbar={setNavbar} />
      <SystemBehavior setNavbar={setNavbar} />
      <Billing setNavbar={setNavbar} />
      <div ref={ref}>
        <CaringForYourPanels />
        <KeepingAnEye />
        <IfYouNeedAHand />
        {showLogin && <Login />}
      </div>
      <Footer />
    </PanelLayout>
  );
};

export default CustomerOwned;
