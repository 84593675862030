import React, { useState } from 'react';
import PanelLayout, { HomeNavBar } from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import Home from './Home';
import MeetTheTeam from './MeetTheTeam';
import MeetTheTeamList from './MeetTheTeamList';
import EvenMoreExperts from './EvenMoreExperts';
import Login from './Login';
import Footer from '../shared/Footer';

export default () => {
  const [navbar, setNavbar] = useState(<HomeNavBar />);
  const showLogin = useShowLogin();

  return (
    <PanelLayout navbar={navbar}>
      <Home />
      <MeetTheTeam setNavbar={setNavbar} />
      <MeetTheTeamList setNavbar={setNavbar} />
      <EvenMoreExperts setNavbar={setNavbar} />
      {showLogin && <Login />}
      <Footer />
    </PanelLayout>
  );
};
