import { styled } from '@material-ui/styles';
import { Header as BaseHeader } from '../TeamHandoff/UiComponents';

export * from '../TeamHandoff/UiComponents';
export { styled };

export const BaseImage = styled('div')(({src, minheight}) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  minHeight: minheight || '300px',
}));

export const Subheader = styled(BaseHeader)(({theme, align}) => ({
  color: '#272727',
  fontSize: '40px',
  fontWeight: '500',
  lineHeight: '45px',
  textAlign: align || 'center',

  [theme.breakpoints.down('xs')]: {
    fontSize: '30px',
  }
}));

export const SmallText = styled('div')({
  fontSize: '20px',
  lineHeight: '30px',
});
