import React from 'react';
import { Grid, PresentationContainer, Header, Hidden } from './UIComponents'
import { styled } from '@material-ui/styles';
import SaveAsMuchMoneyAsPossible from './SaveAsMuchMoneyAsPossible';
import HungriestAppliances from './HungriestAppliances';
import NavBar from '../shared/NavBar';
import useFocusedComponent from '../shared/useFocusedComponent';
import { Megaphone } from '../shared/Icons';
import CircleGrid from '../shared/CircleGrid';

const BillingImage = styled('div')({
  backgroundImage: `url(/billing.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
});

const BillingContainer = styled('div')({
  position: 'relative',
});

const BillingPresentationContainer = styled(PresentationContainer)({
  fontSize: '18px',
  lineHeight: '28px',

  '& > div': {
    marginTop: 20,
  },


});

const Blue = styled('div')(({theme}) => ({
  color: theme.colors.lightBlue,
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '20px',
}));

const CircleOverlayContainer = styled('div')({
  position: 'absolute',
  width: '200px',
  height: '200px',
  top: 0,
  right: 0,
});

const CircleOverlay = () => (
  <Hidden xsDown>
    <CircleOverlayContainer>
      <CircleGrid rows={4} cols={4} />
    </CircleOverlayContainer>
  </Hidden>
);

export const BillingReminder = () => (
  <BillingContainer>
    <Grid container>
      <Grid item sm xs={12}>
        <BillingImage />
      </Grid>
      <Grid item sm xs={12}>
        <BillingPresentationContainer>
          <CircleOverlay />
          <Megaphone />
          <Blue>Reminder</Blue>
          <Header>
            You will still receive a bill from your utility.
          </Header>
          <div>
            How much energy you end up having to buy from your utility 
            depends on the size of your solar system and how much energy 
            you're actually using.
          </div>
          <div>
            Because of weather and seasonal changes, your utility bill will 
            vary. It helps to think about your energy costs on a yearly basis. 
            The billing credits you earn in summer will help offset your energy costs
            in winter.
          </div>
        </BillingPresentationContainer>
      </Grid>
    </Grid>
  </BillingContainer>
);

export const BillingNavbar = () => <NavBar title="Billing" />;

export default ({setNavbar, region}) => {
  const ref = useFocusedComponent(() => setNavbar(
    <BillingNavbar />
  ));

  return (
    <div ref={ref}>
      <BillingReminder />
      <SaveAsMuchMoneyAsPossible region={region} />
      <HungriestAppliances />
    </div>
  );
};
