import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, PresentationContainer, Header as BaseHeader, Button } from './UiComponents';
import { Chart } from '../shared/Icons';

const Text = styled('div')({
  marginTop: '30px',
  marginBottom: '20px',
});

const LoginImage = styled('div')({
  minHeight: '411px',
  backgroundImage: `url(/team-handoff/mysunrun.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'left center',
  height: '100%',
})

const Header = styled(BaseHeader)({
  wordBreak: 'break-word',
});

const TextGrid = styled(Grid)(({theme}) => ({
  [theme.breakpoints.down('xs')]: {
    order: 1,
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
  }
}));

const LoginButton = () => (
  <div>
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Button href="https://www.mysunrun.com/#/login" variant="contained" color="secondary" size="large">Log in now</Button>
      </Grid>
    </Grid>
  </div>
);

export default () => (
  <Grid container>
    <TextGrid item xs={12} sm>
      <PresentationContainer backgroundcolor="#00B085">
        <Chart />
        <Header>Last, but not least: mySunrun.com</Header>
        <Text>
          Log in to learn about home solar, see energy production, pay bills, and more.
        </Text>
        <LoginButton />
      </PresentationContainer>
    </TextGrid>
    <Grid item xs={12} sm>
      <LoginImage />
    </Grid>
  </Grid>
);
