import React, { useState } from 'react';
import PanelLayout, { HomeNavBar } from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import Home from './Home';
import SystemDesign from './SystemDesign';
import SystemBehavior from './SystemBehavior';
import Billing from './Billing';
import Guarantee from './Guarantee';
import Login from './Login';
import Footer from '../shared/Footer';

export default () => {
  const [navbar, setNavbar] = useState(<HomeNavBar />);
  const showLogin = useShowLogin();

  return (
    <PanelLayout navbar={navbar}>
      <Home setNavbar={setNavbar} />
      <SystemDesign setNavbar={setNavbar} />
      <SystemBehavior setNavbar={setNavbar} />
      <Billing setNavbar={setNavbar} />
      <Guarantee setNavbar={setNavbar} />
      {showLogin && <Login setNavbar={setNavbar} />}
      <Footer />
    </PanelLayout>
  );
}
