import React from 'react';
import { Grid, Button, Header } from '../IntroToSolar/UIComponents';
import { styled } from '@material-ui/styles';
import { Chart } from './Icons';

const LoginContainer = styled(Grid)({
  backgroundColor: '#00B085',
  minHeight: '500px',
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  justifyContent: 'center',
});

const TextContainer = styled('div')(({theme}) => ({
  display: 'flex',
  overflow: 'hidden',
  height: '80%',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  color: '#FFF',

  '& p': {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '0',
  },

  '& > *': {
    margin: '0 5%',
    marginRight: '20%',

    [theme.breakpoints.down('xs')]: {
      marginRight: '5%',
    }
  },

  '& img': {
    height: '70px',
    width: '70px',
  },

  '& button': {
    width: '100%',
  }
}));

const LoginButton = () => (
  <div>
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Button href="https://www.mysunrun.com/#/login" variant="contained" color="secondary" size="large">Log in now</Button>
      </Grid>
    </Grid>
  </div>
);

const Login = ({imageSrc, imageAlt, header, children}) => (
  <Grid container>
    <Grid item xs={12} sm>
      <img src={imageSrc} alt={imageAlt} style={{width: '100%', height: '100%'}} />
    </Grid>
    <LoginContainer item xs={12} sm>
      <TextContainer>
        <Chart />
        <Header>{header}</Header>
        <p>
          {children}
        </p>
        <LoginButton />
      </TextContainer>
    </LoginContainer>
  </Grid>
);

export default Login;
