const eastCoast = [
  {
    title: 'Solar Panels',
    description: 'Solar panels convert sunlight into direct current electricity (DC).',
  },
  {
    title: 'Inverter',
    description: 'Your inverter tells energy where to flow and converts it into alternating current electricity (AC), so it can be used in your home',
  },
  {
    title: 'Battery',
    description: 'Your battery stores extra solar energy for later use, like during a power outage.',
  },
  {
    title: 'Sunrun Meter',
    description: 'Solar production is measured and monitored by your Sunrun meter so we can make sure your system is producing as much energy as possible',
  },
  {
    title: 'Main Electrical Panel',
    description: 'Your electrical panel distributes AC electricity to your entire home. You probably know it by its other name, "Fuse Box".',
  },
  {
    title: 'Backup Panel',
    description: 'Your backup panel connects your battery to the breakers you chose to backup during installation',
  },
  {
    title: 'Utility Meter',
    description: 'When the sun is shining, you may get all of your energy from solar, but due to things like weather, winter and nighttime, you will still have to buy some energy the old-fashioned way.'
  }
];

const westCoast = [
  {
    title: 'Solar Panels',
    description: 'Solar panels convert sunlight into direct current electricity (DC).',
  },
  {
    title: 'Inverter',
    description: 'Your inverter tells energy where to flow and converts it into alternating current electricity (AC), so it can be used in hour home',
  },
  {
    title: 'Battery',
    description: 'Your battery stores power for later use, like during a power outage or when utility prices are highest.',
  },
  {
    title: 'Sunrun Meter',
    description: 'Solar production is measured and monitored by your Sunrun meter so we can make sure your system is producing as much energy as possible',
  },
  {
    title: 'Main Electrical Meter',
    description: 'Your electrical panel distributes AC electricity to your entire home. You probably know it by its other name, "Fuse Box".',
  },
  {
    title: 'Backup Panel',
    description: 'Your backup panel connects your battery to the breakers you chose to backup during installation',
  },
  {
    title: 'Utility Meter',
    description: 'When the sun is shining, you may get all of your energy from solar, but due to things like weather, winter and nighttime, you will still have to buy some energy the old-fashioned way.'
  }
];

const hawaii = [
  {
    title: 'Solar Panels',
    description: 'Solar panels convert sunlight into direct current electricity (DC).',
  },
  {
    title: 'Inverter',
    description: 'Your inverter tells energy where to flow and converts it into alternating current electricity (AC), so it can be used in hour home',
  },
  {
    title: 'Battery',
    description: 'Your battery stores power for later use.',
  },
  {
    title: 'Sunrun Meter',
    description: 'Solar production is measured and monitored by your Sunrun meter so we can make sure your system is producing as much energy as possible',
  },
  {
    title: 'Main Electrical Meter',
    description: 'Your electrical panel distributes AC electricity to your entire home. You probably know it by its other name, "Fuse Box".',
  },
  {
    title: 'Quad Outlet',
    description: 'When utility power goes out, your battery kicks in to provide uninterrupted power to this set of four outlets, which is located near your inverter.',
  },
  {
    title: 'Utility Meter',
    description: 'When the sun is shining, you may get all of your energy from solar, but due to things like weather, winter and nighttime, you will still have to buy some energy the old-fashioned way.'
  }
];

export default {
  eastCoast,
  westCoast,
  hawaii,
  texas: eastCoast,
};
