import React from 'react';
import { FullImage } from './UiComponents';
import { styled } from '@material-ui/styles';

const Container = styled('div')(({size, smsize, theme, align}) => ({
  height: `${size}px`,
  width: `${size}px`,
  position: 'absolute',
  top: `-${size/2}px`,
  
  ...align === 'right' && {right: '5%'},
  ...align === 'center' && {left: '50%', marginLeft: `-${size/2}px`},
  ...align === 'left' && {left: '5%'},
}));

const GuaranteeImage = styled(FullImage)({
  boxShadow: '4px 4px 10px rgba(51, 51, 51, .3)',
  borderRadius: '100%',
});

export default props => (
  <Container {...props}>
    <GuaranteeImage src="/guarantee-seal.png" alt="Sunrun Guarantee Seal" />
  </Container>
);