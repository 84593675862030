import React from 'react';
import { Grid, PresentationContainer, Header, styled } from './UIComponents';
import PickYourState from './PickYourState';

const Text = styled('div')({
  margin: '30px 0',
});

const OrderableGrid = styled(Grid)(({theme, smorder}) => ({
  [theme.breakpoints.down('sm')]: {
    order: smorder || 0,
  }
}));

const NeighborhoodImage = styled('div')({
  backgroundImage: 'url(/savings/neighborhood.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  minHeight: '300px',
  height: '100%',
  width: '100%',
});

const Home = stateProps => (
  <Grid container>
    <OrderableGrid item md sm={12} smorder={1} xs={12}>
      <PresentationContainer>
        <Header>
          You made a smart investment
        </Header>
        <Text>
          By now, you know a lot goes into how you think about your energy costs with solar.
          Let's review how and when you'll see a change.
        </Text>
        <PickYourState {...stateProps} />
      </PresentationContainer>
    </OrderableGrid>

    <OrderableGrid item md sm={12} xs={12}>
      <NeighborhoodImage />
    </OrderableGrid>
  </Grid>
);

export default Home;
