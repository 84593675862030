import React from 'react';
import { styled } from './UIComponents';

const STATES = [
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'Deleware',
  'Florida',
  'Hawaii',
  'Illinois',
  'Maryland',
  'Massachusetts',
  'New Mexico',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New York',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'Texas',
  'Utah',
  'Vermont',
  'Wisconsin',
];

const Label = styled('label')({
  color: '#00B085',
  fontSize: '25px',
  fontWeight: '500',
  marginBottom: '20px',
});

const Select = styled('select')({
  height: '50px',
  maxWidth: '350px',
  fontSize: '20px',
  borderRadius: 0,
  border: '2px solid #00B085',
});

const PickYourState = ({state, setState}) => (
  <>
    <Label htmlFor="pick-your-state">Pick your state</Label>
    <Select id="pick-your-state" value={state} onChange={e => setState(e.target.value)}>
      <option value="" readOnly>Select</option>
      {STATES.map(state => <option key={state} value={state}>{state}</option>)}
    </Select>
  </>
);

export default PickYourState;
