import React from 'react';
import { Grid, Bullet, Hidden, Box } from './UIComponents';
import { styled } from '@material-ui/styles';

const BulletHeader = styled('div')(({theme}) => ({
  fontSize: '25px',
  lineHeight: '35px',
  fontWeight: '500',
  minHeight: '67px',

  [theme.breakpoints.down('xs')]: {
    marginBottom: '10px',
    marginTop: '20px',
    minHeight: 0,
  }
}));

const BulletContent = styled('div')({
  color: '#4A4A4A',
  fontSize: '18px',
  lineHeight: '25px',
});

const BulletDivider = styled(Grid)({
  height: '40px',
});

export default ({number, header, children, ...spacingProps}) => (
  <Box {...spacingProps}>
    <Grid container>
      <Grid item xs={12} sm={2}><Bullet>{number}</Bullet></Grid>
      <Grid item xs={12} sm={10}><BulletHeader>{header}</BulletHeader></Grid>
      <Hidden xsDown><BulletDivider item xs={12} /></Hidden>
      <Grid item xs={12}><BulletContent>{children}</BulletContent></Grid>
    </Grid>
  </Box>
);
