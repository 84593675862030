import React from 'react';
import { Sun, Winter, Cloud } from '../shared/Icons';

const WARM_WEATHER_STATES = [
  'Nevada',
  'Arizona',
  'Texas',
  'Florida',
  'South Carolina',
];

const warmWeatherData = {
  left: {
    image: '/savings/summer-fall.png',
    icon: <Sun size={50} />,
    text: 'With long, hot days you may need more power than the solar system produces. You will buy that from the utility on top of your Sunrun bill.',
    header: 'In summer and fall, you\'ll likely pay the same, or a little more.',
  },
  right: {
    image: '/savings/winter-spring.png',
    icon: <Cloud size={50} />,
    text: "With milder weather, you'll use less energy. Your panels may provide all of the power you need even though days are shorter.",
    header: "In winter and spring, you'll likely pay less.",
  },
  footer: 'Year after year, it can add up to savings.',
  subFooter: '',
};

const otherData = {
  left: {
    image: '/savings/summer.png',
    icon: <Sun size={50} />,
    text: 'With longer days and a fixed Sunrun bill, you\'ll likely spend less on energy.',
    header: 'In summer, you\'ll likely pay less',
  },
  right: {
    image: '/savings/winter.png',
    icon: <Winter size={50} />,
    header: 'In winter, you\'ll likely pay a little more.',
    text: 'With shorter days and a fixed Sunrun bill, you\'ll likely spend a little more on energy.',
  },
  footer: 'All together, it can add up to savings.',
  subFooter: 'The rest of the year, you shouldn\'t see much difference',
};

const warmWeatherGraphData = {
  imageBase: '/savings/graph/warm',
  spring: 'As the days get longer, you may get all of your energy from solar.',
  summer: 'When temperatures peak, so does your energy usage. You\'ll buy some energy from the utilities.',
  fall: 'You begin to use less energy and you may get all of your power from solar again.',
  winter: 'With solar power and billing credits, all of your usage is covered.',
}

const defaultGraphData = {
  imageBase: '/savings/graph/default',
  spring: 'Billing credits begin to accumulate.',
  summer: 'Billing credits peak. Extra will roll over into the fall.',
  fall: 'Billing credits start to taper off.',
  winter: 'The last of your billing credits are used up.',
}

export const getFourSeasonsData = state => WARM_WEATHER_STATES.includes(state) ? warmWeatherData : otherData;

export const getSurplusGraphData = state => WARM_WEATHER_STATES.includes(state) ? warmWeatherGraphData : defaultGraphData;
