import React from 'react';
import PanelLayout from '../shared/PanelLayout';
import useShowLogin from '../shared/useShowLogin';
import Home from './Home';
import ReadyToHelp from './ReadyToHelp';
import HelpSteps from './HelpSteps';
import WePromised from './WePromised';
import KeepingAnEye from './KeepingAnEye';
import CaringForYourPanels from './CaringForYourPanels';
import Login from './Login';
import Footer from '../shared/Footer';

export default () => {
  const showLogin = useShowLogin();

  return (
    <PanelLayout>
      <Home />
      <ReadyToHelp />
      <HelpSteps />
      <WePromised />
      <KeepingAnEye />
      <CaringForYourPanels />
      {showLogin && <Login />}
      <Footer />
    </PanelLayout>
  )
};

