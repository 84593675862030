import React from 'react';
import { styled } from '@material-ui/styles';
import { Header, Grid } from './UIComponents';
import AnchorLink from './AnchorLink';
import CircleGrid from '../shared/CircleGrid';

const TextContainer = styled('div')({
  margin: '30px 60px',
});

const Text = styled('div')({
  fontSize: '20px',
  marginTop: '25px',
  lineHeight: '28px',
});

const GridItemContainer = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '30px',
});

const GridContainer = styled('div')(({ theme }) => ({
  height: '250px',

  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}));

const MobileGridContainer = styled('div')(({ theme }) => ({
  height: '130px',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const GridItem = ({ children }) => (
  <GridItemContainer item xs={12} sm={6} md={4}>
    {children}
  </GridItemContainer>
);

const Links = () => (
  <>
    <TextContainer>
      <Header color='#000'>We're here to help.</Header>
      <Text>
        You probably have questions about your solar system. We put together
        some helpful guides on the subjects people ask about the most. Click the
        tiles below to start exploring.
      </Text>
    </TextContainer>
    <Grid container>
      <GridItem>
        <AnchorLink
          to='/getting-started'
          title='Getting started with solar'
          img='/directory/intro-to-solar.png'
        >
          Everything you need to know to understand how your system will work.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/getting-started-brightbox'
          title='Getting started with Brightbox'
          img='/directory/brightbox.png'
        >
          Everything you need to know to understand how your system and
          Brightbox home battery will work.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/meet-our-team'
          title='Meet the Sunrun team'
          img='/directory/meet-our-team.png'
        >
          Behind every solar home is a team of experts. Learn more about the
          people who are with you every step of the way.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/solar-savings'
          title='Understanding solar savings'
          img='/directory/solar-savings.png'
        >
          When you go solar, the way you pay for electricity changes. Learn what
          to expect in your first year.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/solar-care-and-maintenance'
          title='Solar care and maintenance'
          img='/directory/solar-care-and-maintenance.png'
        >
          From the time you first turn your system on, we've got you covered.
          Learn more about the Sunrun Guarantee and panel care.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/customer-owned-onboarding'
          title='Education for solar system owners'
          img='/directory/customer-owned-onboarding.jpg'
        >
          If you purchased your system upfront or through a loan program, here's
          what you need to know about how your system will work.
        </AnchorLink>
      </GridItem>

      <GridItem>
        <AnchorLink
          to='/sell-my-home'
          title='Selling a home with Sunrun solar'
          img='/sell-your-home/selling-a-home-with-solar.jpg'
        >
          What do I need to do to sell my Sunrun home? Not much. First, we
          recommend downloading one of our three service transfer packets.
        </AnchorLink>
      </GridItem>
    </Grid>

    <GridContainer>
      <CircleGrid cols={25} rows={4} color='#001A3C' />
    </GridContainer>
    <MobileGridContainer>
      <CircleGrid cols={14} rows={4} xs='true' color='#001A3C' />
    </MobileGridContainer>
  </>
);

export default Links;
