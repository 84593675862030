 import { useEffect, useRef } from 'react';

const navBarHeight = 60;
const yOffset = 40;

function useFocusedComponent(onFocusCallback) {
  const componentRef = useRef(null);
  
  useEffect(() => {
    function handleScroll() {
      const component = componentRef.current;
      if (! component) return;

      const {y, height} = component.getBoundingClientRect();

      if (y - yOffset <= navBarHeight && height + y - yOffset > navBarHeight) onFocusCallback();
    }

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return componentRef;
};

export default useFocusedComponent;
