import React from 'react';
import { styled, SmallText } from './UIComponents';

const Container = styled('div')(({theme, width}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#272727',

  '& > div': {
    width: width || '431px',
    margin: '40px 0',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '80%',
    }
  }
}));

const Header = styled('div')(({underline}) => ({
  fontSize: '30px',
  lineHeight: '40px',
  fontWeight: '500',
  padding: '15px 0',
  borderTop: '1px solid #CCC',
  margin: '15px 0',

  ...underline && {minHeight: '80px', borderTop: 'none', borderBottom: '1px solid #CCC', fontFamily: 'sunrunSerif, serif'},
}));

const IconSegment = ({icon, header, children, underline, width}) => (
  <Container width={width}>
    <div>
      {icon}
      <Header underline={underline ? 1 : 0}>{header}</Header>
      <SmallText>{children}</SmallText>
    </div>
  </Container>
);

export default IconSegment;
