export const eastCoast = [
  {
    header: 'Time of Day',
    content: 'Your solar production rises and falls with the sun.',
    image: '/tod-chart.png'
  },
  {
    header: 'As The Sun Sleeps',
    image: '/east-coast/night.gif',
    content: 'You do not produce any solar power. All of your energy comes from your utility.',
    modalLink: 'Why not use the battery?',
    modal: {
      header: 'Why not use the battery?',
      content: [
        'To keep your battery fully charged so it is ready to go in the event of a power outage.',
      ] 
    }
  },
  {
    header: 'As The Sun Rises',
    image: '/east-coast/sunrise.gif',
    content: 'Your solar system starts to produce energy, which gets used immediately',
    subContent: 'You may still have to buy some additional energy from your utility',
    modalLink: 'Your battery',
    modal: {
      header: 'Your Battery',
      content: [
        'If your battery isn\'t already full, it will charge in the morning when utility prices are low.',
      ]
    }
  },
  {
    header: 'As The Sun Peaks In The Sky',
    image: '/east-coast/midday.gif',
    content: 'You are producing as much solar energy as possible.',
    subContent: 'This likely means you are producing more energy than you can use. Extra energy is sent into the grid in exchange for a billing credit from your utility',
    modalLink: 'Where does my solar power actually go?',
    modal: {
      header: 'Where does my solar power actually go?',
      content: [
        'By this time of day, your battery should be fully charged. Extra energy will be sent into the grid.',
      ]
    }
  },
  {
    header: 'As The Sun Sets',
    image: '/east-coast/sunset.gif',
    content: 'Your production declines. Demand soars.',
    subContent: 'Your system produces less energy and your battery kicks in to power your home for the night.',
  },
  {
    header: 'As The Sun Sleeps',
    image: '/east-coast/night.gif',
    content: 'Your solar production returns to zero, and all of  your energy comes from your utility.',
    modalLink: 'Your system sleeps with one eye open',
    modal: {
      header: 'Your system sleeps with one eye open.',
      content: [
        'Don\'t worry if the indicator lights are off. Your system is just resting, but it still has one eye on your utility.',
        'If there is an outage, your battery will spring into action to provide seamless backup power.',
      ]
    }
  }
];

export const westCoast = [
  {
    header: 'Time of Day',
    content: 'Your solar production rises and falls with the sun.',
    image: '/tod-chart.png'
  },
  {
    header: 'As The Sun Sleeps',
    image: '/west-coast/night.gif',
    content: 'You do not produce any solar power. All of your energy comes from your utility.',
    modalLink: 'Why not use the battery?',
    modal: {
      header: 'Why not use the battery?',
      content: [
        'To save you as much money as possible.',
        'Home batteries degrade with use, just like your cell phone, so we designed your system to maximize the savings of every kilowatt.',
        'When utility prices are low, like overnight, using your battery does not save much money, so we won\'t',
      ] 
    }
  },
  {
    header: 'As The Sun Rises',
    image: '/west-coast/sunrise.gif',
    content: 'Your solar system starts to produce energy, which gets used immediately',
    subContent: 'You may still have to buy some additional energy from your utility',
    modalLink: 'Your battery',
    modal: {
      header: 'Your Battery',
      content: [
        'If your battery isn\'t already full, it will charge in the morning when utility prices are low.',
      ]
    }
  },
  {
    header: 'As The Sun Peaks In The Sky',
    image: '/west-coast/midday.gif',
    content: 'You are producing as much solar energy as possible.',
    subContent: 'This likely means you are producing more energy than you can use. Extra energy is sent into the grid in exchange for a billing credit from your utility',
    modalLink: 'Where does my solar power actually go?',
    modal: {
      header: 'Where does my solar power actually go?',
      content: [
        'Your home gets the first crack at using the solar energy your produce.',
        'If you are producing more than you\'re using, it will go to the battery',
        'If the battery is full, it is sold to your utility company for a handsome profit',
      ]
    }
  },
  {
    header: 'As The Sun Sets',
    image: '/west-coast/sunset.gif',
    content: 'Production declines, demand soars, and your battery fills in the gaps.',
    subContent: 'As utility rates skyrocket, your system pulls energy from your battery as well as the grid, protecting you from high prices.',
    modalLink: 'Your battery will never empty',
    modal: {
      header: 'Your battery will never empty.',
      content: [
        'We designed your system to reserve at least 20% of its charge for emergencies, like a power outage.',
      ]
    }
  },
  {
    header: 'As The Sun Sleeps',
    image: '/west-coast/night.gif',
    content: 'Your solar production returns to zero, and all of  your energy comes from your utility.',
    modalLink: 'Your system sleeps with one eye open',
    modal: {
      header: 'Your system sleeps with one eye open.',
      content: [
        'Don\'t worry if the indicator lights are off. Your system is just resting, but it still has one eye on your utility.',
        'If there is an outage, your battery will spring into action to provide seamless backup power.',
      ]
    }
  }
];

export const hawaii = [
  {
    header: 'Time of Day',
    content: 'Your solar production rises and falls with the sun.',
    image: '/tod-chart.png'
  },
  {
    header: 'As The Sun Sleeps',
    image: '/hawaii/night.gif',
    content: 'Your battery powers your home. If it runs out, utility power takes over.',
  },
  {
    header: 'As The Sun Rises',
    image: '/hawaii/sunrise.gif',
    content: 'Your solar system starts to produce energy, which gets used immediately',
    subContent: 'You may still have to buy some additional energy from your utility',
    modalLink: 'Your battery',
    modal: {
      header: 'Your Battery',
      content: [
        'If your battery isn\'t already full, it will charge in the morning when utility prices are low.',
      ]
    }
  },
  {
    header: 'As The Sun Peaks In The Sky',
    image: '/hawaii/midday.gif',
    content: 'You are producing as much solar energy as possible.',
    modalLink: 'How do I make the most of my extra power?',
    modal: {
      header: 'How do I make the most of my extra power?',
      content: [
        'By this time of day, your battery should be fully charged. Now it\'s time to use it, so we can charge it again before the sun goes down.',
        'This is a great time to use your hungriest appliances.',
      ]
    }
  },
  {
    header: 'As The Sun Sets',
    image: '/hawaii/sunset.gif',
    content: 'Your battery kicks in to power your home for the night.',
    modalLink: 'Save some energy for overnight',
    modal: {
      header: 'Save some energy for overnight.',
      content: [
        'Without the sun, your battery will stop charging. Try to avoid using your hungriest appliances to help your battery power last until the sun comes back.',
      ]
    }
  },
  {
    header: 'As The Sun Sleeps',
    image: '/hawaii/night.gif',
    content: 'Your solar production returns to zero, and all of  your energy comes from your utility.',
    modalLink: 'Your system sleeps with one eye open',
    modal: {
      header: 'Your system sleeps with one eye open.',
      content: [
        'Don\'t worry if the indicator lights are off. Your system is just resting, but it still has one eye on your utility.',
        'If there is an outage, your battery will spring into action to provide seamless backup power.',
      ]
    }
  }
];

export default {
  eastCoast,
  westCoast,
  hawaii,
  texas: eastCoast,
};
