import React from 'react';
import useFocusedComponent from '../shared/useFocusedComponent';
import { BillingReminder, BillingNavbar } from '../IntroToSolar/Billing';

const Billing = ({setNavbar}) => {
  const ref = useFocusedComponent(() => setNavbar(
    <BillingNavbar />
  ));

  return (
    <div ref={ref}>
      <BillingReminder />
    </div>
  );
};

export default Billing;