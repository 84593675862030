import React from 'react';
import { styled } from '@material-ui/styles';

const GuideSectionsContainer = styled('div')(({ theme, finalElement }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginRight: finalElement ? 0 : 100,
  maxWidth: 375,

  [theme.breakpoints.down('xs')]: {
    marginRight: 0,
  },
}));

export default ({ title, subtitle, topics, img, finalElement, alt, pdf }) => {
  return (
    <GuideSectionsContainer finalElement={finalElement}>
      <img alt={alt} src={img} height={100} style={{ alignSelf: 'center' }} />
      <h3
        style={{
          fontSize: 25,
          fontWeight: 500,
          marginBottom: 5,
        }}
      >
        {title}
      </h3>
      <a
        style={{ fontWeight: 400, fontSize: 20 }}
        href={pdf}
        target='_blank'
        rel='noopener noreferrer'
      >
        {subtitle}
      </a>
      <h4 style={{ fontSize: 18, fontWeight: 500, marginBottom: 0 }}>
        In our {title}, we talk about:
      </h4>
      {topics && (
        <ol>
          {topics.map((topic) => (
            <li style={{ marginBottom: 7 }}>{topic}</li>
          ))}
        </ol>
      )}
    </GuideSectionsContainer>
  );
};
