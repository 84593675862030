import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import IconSegment from './IconSegment';
import { styled, BaseImage, Grid, SmallText, Hidden } from './UIComponents';
import {getFourSeasonsData} from './stateData';

const SeasonImage = styled(BaseImage)({
  height: '300px',
  maxWidth: '100%',
});

const Footer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  minHeight: '120px',
  marginBottom: '20px',
});

const BlueHeader = styled('div')({
  color: '#0087FF',
  fontSize: '40px',
  lineHeight: '50px',
  fontWeight: '500',
  textAlign: 'center',
  fontFamily: 'sunrunSerif, serif',
});

const SeasonSegment = ({image, icon, text, header}) => (
  <Grid item md sm={12} xs={12}>
    <SeasonImage src={image} />
    <IconSegment icon={icon} header={header}>
      {text}
    </IconSegment>
  </Grid>
);

const FourSeasons = ({state}) => {
  const {left, right, footer, subFooter} = getFourSeasonsData(state);

  return (
    <>
      <ContentDivider>It takes all four seasons to see the whole picture.</ContentDivider>
      <Grid container>
        <SeasonSegment {...left} />
        <SeasonSegment {...right} />
      </Grid>
      <Hidden xsDown>
        <Footer>
          {subFooter && (<SmallText>{subFooter}</SmallText>)}
          <BlueHeader>{footer}</BlueHeader>
        </Footer>
      </Hidden>
    </>
  );
}

export default FourSeasons;
