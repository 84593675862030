import React from 'react';
import { styled } from '@material-ui/styles';

export const Circle = styled('div')(props => ({
  width: props.xs ? '2px' : '5px',
  height: props.xs ? '2px' : '5px',
  backgroundColor: props.color || '#FFF',
  borderRadius: '100%',
}));

const CircleRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'space-evenly',
  justifyContent: 'space-evenly',
});

const Container = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-evenly',
  justifyContent: 'space-evenly',
});

export default ({rows, cols, color, xs}) => (
  <Container>
    {[...Array(rows)].map((_, x) => (
      <CircleRow key={x}>
        {[...Array(cols)].map((_, y) => (
          <Circle key={y} color={color} xs={xs} />
        ))}
      </CircleRow>
    ))}
  </Container>
);
