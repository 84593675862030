import React from 'react';
import SystemDesignBullets from '../IntroToSolar/SystemDesignBullets';

export default () => {
  const systemDesignData = [
    {
      title: 'Solar Panels',
      description: 'Solar panels convert sunlight into direct current electricity (DC).',
    },
    {
      title: 'Inverter',
      description: 'Your inverter tells energy where to flow and converts it into alternating current electricity (AC), so it can be used in your home.',
    },
    {
      title: 'Sunrun Meter',
      description: 'Solar production is measured and monitored by your Sunrun meter so we can make sure your system is producing as much energy as possible.',
    },
    {
      title: 'Main Electrical Panel',
      description: 'Your electrical panel distributes AC electricity to your entire home. You probably know it by its other name, "Fuse Box."',
    },
    {
      title: 'Utility Meter',
      description: 'When the sun is shining, you may get all of your energy from solar, but due to things like weather, winter, and nighttime, you will still have to buy some energy the old-fashioned way.',
    }
  ];

  return <SystemDesignBullets systemDesignData={systemDesignData} />
}

