import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid } from './UiComponents';
import useFocusedComponent from '../shared/useFocusedComponent';
import NavBar from '../shared/NavBar';

const TeamPanel = styled(Grid)(({ theme, grey }) => ({
  fontSize: '20px',
  lineHeight: '30px',
  backgroundColor: grey ? '#F7F7F7' : '#FFF',

  [theme.breakpoints.up('md')]: {
    padding: '20px',
  },

  [theme.breakpoints.down('xs')]: {
    backgroundColor: '#FFF',
  },
}));

const QuoteBubble = styled('div')({
  border: '1px solid #DEDBD6',
  backgroundColor: '#FFF',
  width: '60%',
  textAlign: 'center',
  padding: '20px',
  position: 'relative',
  marginBottom: '20px',

  '& div': {
    width: 0,
    height: 0,
    position: 'absolute',
    left: '50%',
  },

  '& div.inner': {
    bottom: '-9px',
    marginLeft: '-10px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #FFF',
  },

  '& div.outer': {
    bottom: '-11px',
    marginLeft: '-10px',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '11px solid #DEDBD6',
  },
});

const Quote = ({ children }) => (
  <QuoteBubble>
    {children}
    <div className='outer'></div>
    <div className='inner'></div>
  </QuoteBubble>
);

const ImageContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& img': {
    height: 'auto',
    maxHeight: '400px',
    width: '95%',
    maxWidth: '400px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const MemberContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    padding: '20px',

    '& div.you': {
      margin: '35px',
    },
  },

  '& div': {
    textAlign: 'center',
  },

  '& img': {
    height: '185px',
    width: 'auto',
  },
}));

const ContentContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '& h3': {
    fontFamily: 'sunrunSerif, serif',
    fontSize: '40px',
    lineHeight: '45px',
    marginTop: 0,
    marginBottom: '15px',
    fontWeight: 'normal',
  },

  '& div > div': {
    margin: '20px 0',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const MemberInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
  },

  '& div': {
    borderLeft: '1px solid #656E79',
    padding: '0 5px',
    minWidth: '150px',
    fontFamily: 'sunrunSerif, serif',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  '& h4': {
    margin: 0,
    fontWeight: 'normal',
    color: '#656E79',
    fontSize: '14px',
    fontFamily: 'sunrunSans',
  },
}));

const TeamMember = ({ grey, name, children, expertise, quote, setNavbar }) => {
  const ref = useFocusedComponent(() => setNavbar(<NavBar title={name} />));
  const isYou = name === 'You';

  return (
    <TeamPanel ref={ref} container grey={grey ? 1 : 0}>
      <MemberContainer item md={2} sm={5} xs={12}>
        {isYou ? (
          <div className='you'>{quote}</div>
        ) : (
          <>
            <Quote>"{quote}"</Quote>
            <img
              src={`/team-handoff/team-members/${name
                .toLowerCase()
                .replace(/ /g, '-')}.png`}
              alt={name}
            />
          </>
        )}
      </MemberContainer>

      <ImageContainer item md={4} sm={7} xs={12}>
        <img
          src={`/team-handoff/team-member-backgrounds/${name
            .toLowerCase()
            .replace(/ /g, '-')}.png`}
          alt={`${name} Background`}
        />
      </ImageContainer>

      <ContentContainer item md={6} sm={12}>
        <div>
          <h3>{name}</h3>
          {children}
        </div>
        <MemberInfoContainer>
          <div>
            <h4>Expertise</h4>
            {expertise}
          </div>
        </MemberInfoContainer>
      </ContentContainer>
    </TeamPanel>
  );
};

export default ({ setNavbar }) => (
  <>
    <TeamMember
      setNavbar={setNavbar}
      grey
      name='You'
      quote="Solar doesn't happen without you."
      expertise='Your home'
    >
      <div>
        You are the most important member of the team. It's your home. Your
        energy. The rest of the team knows how to make solar a reality, but
        solar doesn't happen without you.
      </div>
      <div>
        Our team of specialists and experts will do as much of the work as we
        can, but when it comes to your home, you're the expert, and we may need
        your help.
      </div>
      <div>Welcome to the team &mdash; we're lucky to have you!</div>
    </TeamMember>
    <TeamMember
      setNavbar={setNavbar}
      name='Sales Consultant'
      quote='I teach you about solar.'
      expertise='Teaching'
    >
      <div>
        Your Sales Consultant is your personal solar guru &mdash; breaking down
        all the complexities to help you understand what solar can do for your
        home, and how.
      </div>
      <div>
        They start by getting to know you and your home's unique energy needs,
        then they connect with our design team to come up with a custom plan.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      grey
      name='Site Technician'
      quote='I make sure your home is ready for solar.'
      expertise='Electrical inspection'
    >
      <div>We triple-check every system's design before we build it.</div>
      <div>
        A Site Technician will visit your home to take some measurements and
        check for certain requirements &mdash; like whether your roof is in good
        condition and if we need to make electrical updates &mdash; to confirm
        that your home is ready for solar panels.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      name='Project Coordinator'
      quote='I guide you through installation and activation.'
      expertise='Collaboration'
    >
      <div>
        Your Project Coordinator is your primary contact from the moment you
        sign up until the moment you turn your system on and start producing
        energy.
      </div>
      <div>
        They coordinate the design and installation processes. They deal with
        government permits and connecting to the grid. And they update you every
        step of the way. If you have any questions at all, ask your project
        coordinator.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      grey
      name='Installation Coordinator'
      quote='I schedule your installation and send a crew.'
      expertise='Logistics'
    >
      <div>Our Installation Coordinators are like musical conductors.</div>
      <div>
        They're skilled at bringing together a lot of moving parts &mdash; your
        schedule, our crew's schedule, permits, equipment, and more &mdash; to
        get panels on roofs.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      name='Installation Crew'
      quote='We put panels on your roof.'
      expertise='Construction &amp; Electricity'
    >
      <div>
        When the big day comes, our Installation Crew takes the wheel. They're
        experts in electical engineering, home construction, and solar &mdash;
        and they'll take great care to install your panels carefully, safely,
        and efficiently.
      </div>
      <div>
        Generally, you can expect one foreman and several crew members. The
        foreman will go over the system with you once they've finished the job,
        but feel free to ask questions at any time.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      grey
      name='Customer Care'
      quote="We're with you, long-term."
      expertise='Troubleshooting'
    >
      <div>
        Once you turn on your system and start producing energy, we will connect
        you with the Customer Care team. They will be standing by to answer
        questions and solve problems for the full 25 years of your agreement.
      </div>
      <div>
        Every Customer Care team member is thoroughly trained on everything
        Sunrun. From billing to repair and everything in between, they've seen
        it all. If they ever don't already have an answer for you, they'll find
        one.
      </div>
    </TeamMember>

    <TeamMember
      setNavbar={setNavbar}
      name='Field Service Technicians'
      quote='We fix things.'
      expertise='Repairs'
    >
      <div>
        Our sytems are designed with durability in mind, but 25 years is a long
        time, and occasionally, repairs may be required.
      </div>
      <div>
        If your system is ever in need of repair, we'll send a Field Service
        Technician. Experts in electrical engineering, home construction, and
        solar, they will get your panels back to peak production.
      </div>
    </TeamMember>
  </>
);
