import React from 'react';
import { styled } from '@material-ui/styles';

import PanelLayout from '../shared/PanelLayout';
import WhatDoINeed from './WhatDoINeed';
import GuideSection from './GuideSection';
import MoreInformation from './MoreInformation';

export const Image = styled('div')(({ src }) => ({
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: 350,
  position: 'relative',
}));

export default () => {
  return (
    <PanelLayout>
      <Image src={'/sell-your-home/homefront-palm-trees.jpg'} />
      <div style={{ padding: '20px 50px 20px 50px' }}>
        <WhatDoINeed />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <GuideSection
            pdf={'/sell-your-home/seller-guide.pdf'}
            alt='house for sale sign'
            img='/sell-your-home/house-for-sale.png'
            title='Home Seller Guide'
            subtitle='This packet contains
everything you need to know
about selling a solar home.'
            topics={[
              'Home values and solar',
              'How solar affects the home selling process',
              'How the Customer Agreement comes into play',
              'How fast your home may sell',
              'How solar works',
              'Some FAQs about selling a home with solar',
              'Our contact information',
            ]}
          />
          <GuideSection
            pdf={'/sell-your-home/buyer-guide.pdf'}
            alt='keys'
            img='/sell-your-home/key.png'
            title='Home Buyer Guide'
            subtitle='This packet contains
everything you need to know
about buying a solar home.'
            topics={[
              'The benefits of solar',
              'The problems with traditional power',
              'How solar works',
              'Home values and solar',
              'How solar affects the home buying process',
              'Who is Sunrun',
              'The steps to going solar',
              'Some FAQs about buying a home with solar',
              'Our contact information',
            ]}
          />
          <GuideSection
            pdf={'/sell-your-home/realtor-guide.pdf'}
            img='/sell-your-home/agreement.png'
            alt='handshake'
            title='Home Realtor Guide'
            subtitle='This packet contains
everything you need to know
about buying a solar home.'
            topics={[
              'The benefits of solar',
              'The problems with traditional power',
              'How solar works',
              'Home values and solar',
              'How solar affects the home buying process',
              'How to discuss Sunrun solar with a potential buyer',
              'How the system transfer works',
              'Who is Sunrun',
              'Some FAQs about selling a solar home as a realtor',
              'Our contact information',
            ]}
            finalElement
          />
        </div>
      </div>
      <MoreInformation />
    </PanelLayout>
  );
};
