import React from 'react';
import { Grid, Header } from './UIComponents'
import { styled } from '@material-ui/styles';
import BulletPoint from './BulletPoint';

const HeaderContainer = styled('div')({
  textAlign: 'center',
  margin: '40px 0',
  marginBottom: '60px',
});

const BorderedGrid = styled(Grid)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    borderLeft: '1px solid #c1c1c1',
    borderRight: '1px solid #c1c1c1',
  }
}));

const BulletsContainer = styled(Grid)({
  '& >*': {
    marginBottom: '40px',
  }
});

export default ({region}) => {
  return (
    <>
      <HeaderContainer>
        <Header color="#000">To save as much money as possible:</Header>
      </HeaderContainer>
      <BulletsContainer container style={{marginBottom: '40px'}}>
        <Grid item sm xs={12}>
          <BulletPoint mx={4} number="1" header="Deploy energy efficient lights and appliances.">
            The more efficient your washing machine, the less energy you need.
            Check with your utility to see if they offer any rebates or incentives. 
            They almost always do.
          </BulletPoint>
        </Grid> 
        <BorderedGrid item sm xs={12}>
          {region !== 'hawaii' ? (
            <BulletPoint mx={4} number="2" header="Adjust your thermostat.">
              Air conditioning uses more energy than anything else in your home,
              and it's not even close. Turning down the A/C by even one degree adds up to 
              significant savings.
            </BulletPoint>
          ) : (
            <BulletPoint mx={4} number="2" header="Use your hungriest appliances during the day">
              You can get the most out of your solar production during the day, and your battery at 
              night, by performing high usage chores like laundry while the sun is shining.
            </BulletPoint>
          )}
        </BorderedGrid>
        <Grid item sm xs={12}>
          <BulletPoint mx={4} number="3" header="Don't go overboard.">
            A funny thing happens when people go solar. A lot of times, they
            start using more energy than they did before. You'll save the most if
            you keep your power use stable.
          </BulletPoint>
        </Grid>
      </BulletsContainer>
    </>
  );
};
