import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import { Grid, styled } from './UIComponents';
import IconSegment from './IconSegment';
import { BlueMicrowave, BlueAdjusters, BlueChart } from '../shared/Icons';

const Container = styled('div')(({theme}) => ({
  minHeight: '768px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',

  [theme.breakpoints.down('xs')]: {
    marginTop: '30px',
  }
}));

const UseLessSaveMore = () => (
  <>
    <ContentDivider subtitle="Here are a few things you can do to maximize your savings.">
      Use a little less. Save a little more.
    </ContentDivider>
    <Container>
      <Grid container>
        <Grid item md sm={12} xs={12}>
          <IconSegment width="381px" underline icon={<BlueMicrowave size={80} />} header="Deploy energy efficient lights and applicances.">
            The more efficient your washing machine, the less energy you need. Check with your utility 
            to see if they offer any rebates or incentives. They almost always do.
          </IconSegment>
        </Grid>
        <Grid item md sm={12} xs={12}>
          <IconSegment width="381px" underline icon={<BlueAdjusters size={80} />} header="Adjust your thermostat.">
            Air conditioning uses more energy than anything else in your home, and it's not even close.
            Setting your AC a degree or two warmer can add up to significant savings.
          </IconSegment>
        </Grid>
        <Grid item md sm={12} xs={12}>
          <IconSegment width="381px" underline icon={<BlueChart size={80} />} header="Don't go overboard.">
            A funny thing happens when people go solar. A lot of times, they start using more energy
            than they did before. You'll save the most if you keep your power use stable.
          </IconSegment>
        </Grid>
      </Grid>
    </Container>
  </>
);

export default UseLessSaveMore;
