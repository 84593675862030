import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import { Subheader as BaseSubheader, styled, Grid, SmallText as BaseSmallText } from './UIComponents';
import SurplusGraph from './SurplusGraph';
import { getSurplusGraphData } from './stateData';

const Container = styled('div')({
  minHeight: '1027px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
});

const Disclaimer = styled('div')(({theme}) => ({
  color: '#999',
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: 'center',
  width: '94%',
  marginBottom: '50px',

  [theme.breakpoints.down('xs')]: {
    width: '100%',
  }
}));

const Subheader = styled(BaseSubheader)({
  textAlign: 'left',
  marginBottom: '20px',
});

const HeaderContainer = styled('div')(({theme}) => ({
  margin: '60px auto',
  width: '93%',
}));

const SmallText = styled(BaseSmallText)(({theme}) => ({
  width: '93%',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: '20px',
  }
}));

const SurplusPower = ({state}) => (
  <>
    <ContentDivider>Surplus power never goes to waste.</ContentDivider>
    <Container>
      <HeaderContainer>
        <Subheader>Trade extra energy for a lower utility bill.</Subheader>
        <Grid container>
          <Grid item sm xs={12}>
            <SmallText>
              Solar power needs to be used or stored immediately. Any time you're producing more than 
              you're using, the extra power is sent to the grid in exchange for a billing credit from
              your utility.
            </SmallText>
          </Grid>
          <Grid item sm xs={12}>
            <SmallText>
              These credits will be applied to your next utility bill. When your billing credits
              are bigger than your bill, the extra will roll over to the next month.
            </SmallText>
          </Grid>
        </Grid>
      </HeaderContainer>
      <SurplusGraph {...getSurplusGraphData(state)} />
      <Disclaimer>
        Everyone's home and energy needs are unique. The information here is based on a typical home with solar.
        Your situation may look different.
      </Disclaimer>
    </Container>
  </>
);

export default SurplusPower;
