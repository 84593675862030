import React from 'react';
import { Grid, Hidden, Header } from './UIComponents';
import { styled } from '@material-ui/styles';
import CircleGrid from '../shared/CircleGrid';
import NavBar from '../shared/NavBar';
import useFocusedComponent from '../shared/useFocusedComponent';

const SecondaryHeader = styled('div')(props => ({
  color: props.theme.colors.lightBlue,
  fontSize: '25px',
  lineHeight: '49px',
}));

const Paragraph = styled('p')({
  color: '#272727',
  fontSize: '20px',
  lineHeight: '30px',
});

const ContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-evenly',
  margin: '5%',
})

const TextContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-evenly',
  justifyContent: 'space-evenly',

  '& img': {
    height: '80px',
    width: '80px',
  },
});

export default ({setNavbar}) => {
  const ref = useFocusedComponent(() => setNavbar(
    <NavBar title="Guarantee" />
  ));

  return (
    <div ref={ref}>
      <img src="/guarantee.png" alt="Repairman working on a solar panel" style={{width: '100%'}} />
      <Grid container style={{minHeight: '500px'}}>
        <TextContainer item sm={9} xs={12}>
          <ContentContainer> 
            <Hidden smUp><img src="/guarantee-seal.png" alt="Guarantee Seal" /></Hidden>
            <Header color="#000">The Sunrun Guarantee</Header>
            <div>
              <Grid container>
                <Hidden xsDown>
                  <Grid item lg={4} md={5} style={{display: 'flex', alignItems: "center"}}>
                    <img src="/guarantee-seal.png" alt="Guarantee Seal" style={{width: '238px', height: '238px'}} />
                  </Grid>
                </Hidden>
                <Grid item lg={8} md={7} xs={12}>
                  <SecondaryHeader>We've got your back.</SecondaryHeader>
                  <Paragraph>
                    We'll handle system maintenance and repairs for the full length of 
                    our agreement. We have an entire team dedicated to keeping an eye on your system
                    and making sure it thrives.
                  </Paragraph>
                  <Paragraph>
                    If anything unexpected happens, our Monitoring Team will identify the issue and 
                    provide a solution.
                  </Paragraph>
                </Grid>
              </Grid>
            </div>
          </ContentContainer>
        </TextContainer>
        <Grid item sm={3}>
          <Hidden xsDown>
            <CircleGrid color="#0087FF" rows={9} cols={4} />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};
