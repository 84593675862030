import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid } from './UiComponents';
import ContentDivider from '../shared/ContentDivider';

const CareContainer = styled('div')({
  width: '90%',
  maxWidth: '400px',
  margin: '50px auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& img': {
    maxWidth: '400px',
    maxHeight: '400px',
    width: '100%',
    height: 'auto',
  },

  '& h2': {
    color: '#272727',
    fontSize: '30px',
    width: '100%',
    lineHeight: '34px',
    fontWeight: '500',
  },

  '& div': {
    color: '#001A3C',
    fontSize: '20px',
    lineHeight: '30px',
  }
});

const CareStep = ({image, header, children}) => (
  <Grid item md={4} sm={6} xs={12}>
    <CareContainer>
      {image}
      <h2>{header}</h2>
      <div>{children}</div>
    </CareContainer>
  </Grid>
);

export default () => (
  <div>
    <ContentDivider>
      Caring for your panels
    </ContentDivider>
    <Grid container>
      <CareStep 
        image={<img src="/service/trim-back-trees.png" alt="Tree over solar panel" />}
        header="Trim back trees."
      >
        The more sun on your panels, the better. It's a good idea to keep nearby
        trees trimmed to allow the sunshine to reach your panels for as much
        of the day as possible.
      </CareStep>
      <CareStep
        image={<img src="/service/spray-dusty-panels.png" alt="Hose spraying water on a solar panel" />}
        header="Spray dusty panels."
      >
        Just a few rainy days a year is enough to clean your panels. If you live in a 
        particularly dusty area, you may want to hose them off &mdash; just do it
        from the ground to be safe.
      </CareStep>
      <CareStep
        image={<img src="/service/watch-falling-snow.png" alt="Snow covered solar panel" />}
        header="Watch out for falling snow."
      >
        It's normal for snow to stick to your panels. Please resist the urge to clean
        them. The snow will slide off when it's ready. Try to avoid parking in the 
        landing zone.
      </CareStep>
    </Grid>
  </div>
);
