import React from 'react';
import { styled } from '@material-ui/styles';
import { PresentationContainer, Grid, Hidden, Header as BaseHeader } from './UiComponents';
import CircleGrid from '../shared/CircleGrid';
import FloatingGuarantee from './FloatingGuarantee';

const AccentText = styled('div')(({theme}) => ({
  fontWeight: '500',
  color: '#0087FF',
  marginTop: '40px',

  [theme.breakpoints.up('lg')]: {
    marginTop: '20px',
  }
}));

const Header = styled(BaseHeader)({
  marginTop: '20px',
  marginBottom: '20px',
})

const Content = styled('div')({
  color: '#272727',
  marginTop: '30px',
});

const ToggledGuarantee = () => (
  <>
    <Hidden smDown>
      <FloatingGuarantee size={200} align="left" />
    </Hidden>
    <Hidden mdUp>
      <FloatingGuarantee size={100} align="left" />
    </Hidden>
  </>
);

export default () => (
  <div>
    <img style={{width: '100%', height: 'auto'}} src="/service/promised-solar-energy.png" alt="Smiling customer support reps" />
    <Grid container style={{position: 'relative'}}>
      <ToggledGuarantee />
      <Grid item xs={12} md={9}>
        <PresentationContainer backgroundcolor="#FFF">
          <AccentText>What if my system is underperforming?</AccentText>
          <Header color="#272727">We promised solar energy, and we keep our promises.</Header>
          <Content>
            It's rare, but sometimes panels produce less than we expected. Whether it's because
            of a technical issue or an especially cloudy July, you don't have to worry.
          </Content>
          <Content>
            At your two-year anniversary with Sunrun, we will compare how much energy your panels 
            produced to the production promised in your agreement. If your panels didn't produce as
            much as we expected, we'll issue a refund to make up for the difference.
          </Content>
          <Content>
            All along the way, we'll be monitoring your system.
          </Content>
        </PresentationContainer>
      </Grid>
      <Grid item md={3}>
        <Hidden smDown><CircleGrid cols={4} rows={13} color="#0087FF" /></Hidden>
      </Grid>
    </Grid>
  </div>
);
