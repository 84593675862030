import React from 'react';

export default () => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <h2 style={{ fontWeight: 400, fontSize: 40, marginBottom: 0 }}>
      What do I need to do to sell my Sunrun home?
    </h2>
    <p style={{ fontSize: 18, marginBottom: 40 }}>
      Not much. First, we recommend downloading our service transfer packets.
      There are three of them:
    </p>
    <div
      style={{
        backgroundColor: '#e7f5fb',
        padding: '0px 25px',
        borderRadius: 25,
        display: 'inline-block',
        margin: '0px 0px 50px 20px',
        alignSelf: 'center',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p
          style={{
            marginRight: 20,
            color: 'blue',
            fontWeight: 500,
            fontSize: 18,
          }}
        >
          PRO TIP
        </p>
        <p>
          Download and print several copies of these packets and make them
          available during your open house.
        </p>
      </div>
    </div>
  </div>
);
