import React from 'react';
import { ThemeProvider, styled } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './shared/ScrollToTop';
import IntroToSolar from './IntroToSolar';
import TeamHandoff from './TeamHandoff';
import Onboarding from './Onboarding';
import Service from './Service';
import Directory from './Directory';
import Savings from './Savings';
import CustomerOwned from './CustomerOwned';
import SellMyHome from './SellMyHome';
import './App.css';


const theme = createMuiTheme({
  colors: {
    darkBlue: '#001A3C',
    lightBlue: '#0087FF',
  },
  palette: {
    primary: { 500: '#00B085' },
    secondary: { main: '#FFF' },
  },
  panelHeight: '768px',
});

const AppContainer = styled('div')({
  backgroundColor: '#f7f7f7',
  display: 'flex',
  justifyContent: 'center',
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Router>
          <ScrollToTop>
            <Route path="/getting-started-brightbox" component={IntroToSolar} />
            <Route path="/meet-our-team" component={TeamHandoff} />
            <Route path="/getting-started" component={Onboarding} />
            <Route path="/solar-care-and-maintenance" component={Service} />
            <Route path="/solar-savings" component={Savings} />
            <Route path="/customer-owned-onboarding" component={CustomerOwned} />
            <Route path="/sell-my-home" component={SellMyHome}/>
            <Route path="/" exact component={Directory} />
          </ScrollToTop>
        </Router>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
