import React, { forwardRef } from 'react';
import { Grid, PresentationContainer, Header, OrderableGrid } from './UIComponents'
import { styled } from '@material-ui/styles';

const PowerOutageImage = styled('div')({
  backgroundImage: `url(/onboarding/power-outage.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100%',
  minHeight: '230px',
});

const BlueHeader = styled('div')(({theme}) => ({
  color: theme.colors.lightBlue,
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '20px',
}));

export default forwardRef((props, ref) => {
  return (
    <div ref={ref}>    
      <Grid container>
        <OrderableGrid item sm xs={12} xsorder="2">
          <PresentationContainer>
            <Grid container>
              <Grid item xs={12} sm={9}>
                <BlueHeader>In A Power Outage</BlueHeader>
                <Header>
                  Your solar system turns off, too.
                </Header>
                <div style={{marginTop: '1.5rem', fontSize: '20px', lineHeight: '34px'}}>
                  Your solar panels send extra energy back to the grid, and that
                  could be dangerous to utility workers fixing the outage. For their
                  safety, your system will turn itself off during a power outage. Once
                  power is restored, your system will turn back on automatically.
                </div>
              </Grid>
            </Grid>
          </PresentationContainer>
        </OrderableGrid>
        <OrderableGrid item sm xs={12} xsorder="1">
          <PowerOutageImage />
        </OrderableGrid>
      </Grid>
    </div>
  );
});
