import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, PresentationContainer, Header, Button } from './UIComponents';

const Text = styled('div')({
  marginTop: '30px',
  marginBottom: '20px',
  fontSize: '25px',
  lineHeight: '35px',
});

const LoginImage = styled('div')({
  minHeight: '411px',
  backgroundImage: `url(/savings/family.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'left center',
  height: '100%',
})

const TextGrid = styled(Grid)(({theme}) => ({
  [theme.breakpoints.down('xs')]: {
    order: 1,
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%',
  }
}));

const LoginButton = () => (
  <div>
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Button href="https://www.mysunrun.com/#/login" variant="contained" color="secondary" size="large">Sign Up Now</Button>
      </Grid>
    </Grid>
  </div>
);

export default () => (
  <Grid container>
    <TextGrid item xs={12} sm>
      <PresentationContainer backgroundcolor="#00B085">
        <Header>One more way to save.</Header>
        <Text>
          Sign up for Autopay and save an additional $90/year.
        </Text>
        <LoginButton />
      </PresentationContainer>
    </TextGrid>
    <Grid item xs={12} sm>
      <LoginImage />
    </Grid>
  </Grid>
);
