import React from 'react';
import { styled } from '@material-ui/styles';
import { Hidden, Header, PresentationContainer, Grid, OrderableGrid, ScalableImage } from './UiComponents';
import { Handshake } from '../shared/Icons';
import FloatingGuarantee from './FloatingGuarantee';

const Content = styled('div')({
  color: '#272727',
  marginTop: '40px',
});

const ToggledGuarantee = () => (
  <>
    <Hidden smDown>
      <FloatingGuarantee size={273} align="right" />
    </Hidden>
    <Hidden mdUp>
      <FloatingGuarantee size={150} align="center" />
    </Hidden>
  </>
);

export default () => (
  <Grid container style={{position: 'relative'}}>
    <ToggledGuarantee />
    <OrderableGrid smorder="1" item xs={12} md>
      <PresentationContainer backgroundcolor="#F7F7F7">
        <Handshake />
        <Header color="#272727">We're always ready to help.</Header>
        <Content>
          When you go solar with Sunrun, you're getting more than
          just panels on your roof. You're signing up for 25 years
          of service and support.
        </Content>
        <Content>
          We carefully choose our equipment with durability in mind,
          but something is bound to come up during our agreement.
          Here's what to do if it does.
        </Content>
      </PresentationContainer>
    </OrderableGrid>
    <OrderableGrid item xs={12} md>
      <ScalableImage src="/service/ready-to-help.png" minheight="300px"/>
    </OrderableGrid>
  </Grid>
);
