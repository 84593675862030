import React from 'react';
import { styled } from '@material-ui/styles';

export const NavBarBase = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  height: '60px',
  width: '100%',
  zIndex: 99,
  color: '#FFF',
  backgroundColor: theme.colors.lightBlue,

  [theme.breakpoints.down('xs')]: {
    color: '#001A3C',
    backgroundColor: '#FFF',
  }
}));

const NavBar = styled(NavBarBase)({
  backgroundColor: '#FFF',
});

export const HomeNavBar = () => (
  <NavBar>
    <img src="/logo-header.svg" style={{marginLeft: '5%'}} alt="Sunrun Logo" />
  </NavBar>
);

const NavbarContainer = styled('div')({
  maxWidth: '1440px',
  position: 'fixed',
  width: '100%',
  zIndex: 99,
});

const PanelContainer = styled('div')({
  paddingTop: '60px',
  width: '100%',
  maxWidth: '1440px',
  backgroundColor: '#FFF',
});

export default ({navbar, children}) => (
  <>
    <NavbarContainer>{navbar || <HomeNavBar />}</NavbarContainer>
    <PanelContainer>{children}</PanelContainer>
  </>
);
