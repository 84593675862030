import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/styles';
import CircleGrid from '../shared/CircleGrid';
import Testimonial from './Testimonial';
import testimonials from './testimonialData';

const Container = styled('div')(({theme}) => ({
  backgroundColor: '#001A3C',
  minHeight: '340px',
  width: '100%',
  display: 'flex',

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    minHeight: '450px',
  }
}));

const Circle = styled('div')(({selected}) => ({
  height: '10px',
  width: '10px',
  margin: '5px',
  border: '1px solid #0087FF',
  alignSelf: 'flex-start',
  cursor: 'pointer',
  borderRadius: '50%',
  ...selected && {backgroundColor: '#0087FF'}
}));

const GridContainer = styled('div')(({theme}) => ({
  width: '260px',
  height: '340px',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  }
}));

const MobileGridContainer = styled('div')(({theme}) => ({
  width: '100%',
  height: '110px',

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  }
}));

const CircleContainer = styled('div')({
  width: '100%',
  display: 'flex',
  bottom: '30px',
})

const TestimonialsContainer = styled('div')(({theme}) => ({
  height: '340px',
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  backgroundImage: 'url(/directory/quote.png)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '5% 25%',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  '& > div': {
    maxWidth: '950px',
    width: '80%',
    position: 'absolute',
  },

  [theme.breakpoints.down('xs')]: {
    backgroundPosition: '20% 10%',
    backgroundSize: '110px 96px',
  }
}));

const TestimonialList = () => {
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTestimonialIndex((currentTestimonialIndex + 1) % testimonials.length);
    }, 7000);

    return () => clearInterval(timeout);
  }, [currentTestimonialIndex]);

  return (
    <Container>
      <MobileGridContainer>
        <CircleGrid cols={14} rows={4} xs="true" />
      </MobileGridContainer>
      <TestimonialsContainer>
        {testimonials.map((testimonial, idx) => (
          <Testimonial {...testimonial} key={idx} active={idx === currentTestimonialIndex} />
        ))}
        <CircleContainer>
        {testimonials.map((_, idx) => <Circle onClick={() => setCurrentTestimonialIndex(idx)} key={idx} selected={currentTestimonialIndex === idx} />)}
        </CircleContainer>
      </TestimonialsContainer>
      <GridContainer>
        <CircleGrid cols={4} rows={6} />
      </GridContainer>
    </Container>
  );
}

export default TestimonialList;
