import React from 'react';
import ContentDivider from '../shared/ContentDivider';
import { styled, Subheader, Grid, SmallText } from './UIComponents';
import BillingCalculation from './BillingCalculation';
import { Grid as GridIcon, SolarHouse } from '../shared/Icons';

const Container = styled('div')({
  backgroundColor: '#F7F7F7',
  minHeight: '1347px',
});

const ContentContainer = styled('div')(({theme}) => ({
  [theme.breakpoints.down('xs')]: {
    padding: '40px',
  }
}));

const HeaderContainer = styled('div')({
  minHeight: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const BillContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  color: '#272727',

  '& > div': {
    maxWidth: '550px',
    margin: '40px 0',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }
});

const BillHeader = styled('div')({
  fontSize: '30px',
  width: '200px',
  marginBottom: '30px',
  fontWeight: '500',
  borderBottom: '1px solid #CCC'
});

const CircleIcon = styled('div')(({start, stop}) => ({
  width: '250px',
  height: '250px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(180deg, ${start} 66%, ${stop} 68%)`,
  marginBottom: '50px',

  '& img': {
    height: '200px',
    width: '200px',
  }
}));


const Bill = ({icon, header, children}) => (
  <Grid item md sm={12} xs={12}>
    <BillContainer>
      <div>
        {icon}
        <BillHeader>{header}</BillHeader>
        <SmallText>{children}</SmallText>
      </div>
    </BillContainer>
  </Grid>
);

const Bills = () => (
  <Container>
    <ContentDivider>Going solar means two bills: one from Sunrun, and one from your utility.</ContentDivider>
    <ContentContainer>
      <HeaderContainer>
        <Subheader>Bills are monthly. Savings are yearly.</Subheader>
      </HeaderContainer>
      <Grid container>
        <Bill header="Sunrun bill" icon={<CircleIcon start="#ffd45c" stop="#ffb021"><SolarHouse /></CircleIcon>}>
          Your Sunrun bill is for your home solar service. You pay for the service, not the
          energy you produce, so your payment will stay the same from month to month.
        </Bill>
        <Bill header="Utility bill" icon={<CircleIcon start="#48A9FF" stop="#40A5FF"><GridIcon /></CircleIcon>}>
          Your utility bill is for energy you buy when you use more than your panels produce.
          Your usage varies from month to month based on things like changing weather and seasons.
        </Bill>
      </Grid>
      <BillingCalculation />
    </ContentContainer>
  </Container>
);

export default Bills;
