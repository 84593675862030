import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid, Hidden, PresentationContainer, Header } from './UiComponents';

const Text = styled('div')({
  marginTop: '30px',
})

const HomeImage = styled('div')({
  backgroundImage: `url(/team-handoff/home.png)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'left center',
  height: '100%',
})

export default () => (
  <Grid container>
    <Grid item sm>
      <Hidden xsDown>
        <HomeImage />
      </Hidden>
    </Grid>
    <Grid item xs={12} sm>
      <PresentationContainer>
        <Header>Going solar is a team effort. We're here for you.</Header>
        <Text>
          No one goes solar alone. It takes a lot of experts to make solar work.
        </Text>
        <Text>
          Sunrun brings them together to make solar easier than ever.
        </Text>
      </PresentationContainer>
    </Grid>
  </Grid>
);
