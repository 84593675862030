import React from 'react';
import PanelLayout from '../shared/PanelLayout';
import Testimonials from './Testimonials';
import Links from './Links';
import Footer from '../shared/Footer';

const Directory = () => (
  <PanelLayout>
    <Testimonials />
    <Links />
    <Footer />
  </PanelLayout>
);

export default Directory;
