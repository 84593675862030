import React from 'react';
import { styled } from '@material-ui/styles';
import { Header, Hidden } from './UiComponents';
import { HomeNavBar } from '../shared/PanelLayout';
import useFocusedComponent from '../shared/useFocusedComponent';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 0',
  justifyContent: 'space-evenly',
  fontSize: '20px',
  lineHeight: '28px',
  textAlign: 'center',

  '& > div, h1': {
    margin: '20px 0',
  }
})

const BlueText = styled('div')({
  color: '#0087FF',
  fontWeight: '500',
});

const TeamContainer = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-around',
  width: '100%',
});

const TeamMemberContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '388px',
  width: '125px',

  '& img': {
    width: 'auto',
  },

  '& div': {
    marginTop: '20px',
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'center',
  },
})

const TeamMember = ({name, imageHeight=250}) => (
  <TeamMemberContainer>
    <img src={`/team-handoff/team-members/${name.toLowerCase().replace(/ /g, '-')}.png`} alt={name} style={{height: `${imageHeight}px`}} />
    <div>{name}</div>
  </TeamMemberContainer>
);

export default ({setNavbar}) => {
  const ref = useFocusedComponent(() => setNavbar(
    <HomeNavBar />
  ));

  return (
    <Container ref={ref}>
      <BlueText>Meet the Sunrun Team</BlueText>
      <Header color="#000">We have an expert for every step.</Header>
      <div style={{maxWidth: '680px'}}>
        From design and installation to a lifetime of service and care, Sunrun
        pairs you with the perfect person to guide you through it
      </div>
      <Hidden xsDown>
        <TeamContainer>
          <TeamMember name="Sales Consultant" />
          <TeamMember name="Site Technician" />
          <TeamMember name="Project Coordinator" />
          <TeamMember name="Installation Coordinator" />
          <TeamMember name="Installation Crew" imageHeight={300} />
          <TeamMember name="Customer Care" />
          <TeamMember name="Field Service Technicians" />
        </TeamContainer>
      </Hidden>
    </Container>
  );
}
