import React from 'react';
import { styled } from '@material-ui/styles';
import { Grid } from './UiComponents';
import ContentDivider from '../shared/ContentDivider';


const BulletPoint = styled('div')(({theme}) => ({
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#0087FF',
  color: '#FFF',
  fontWeight: '500',
  marginTop: '15px',

  [theme.breakpoints.up('md')]: {
    margin: 'auto',
    marginTop: '36px',
  }
}));

const Container = styled('div')({
  backgroundColor: 'rgba(191, 225, 255, .5)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const Content = styled('div')({
  color: '#272727',
  fontSize: '20px',
  lineHeight: '28px',
  marginBottom: '30px',
});

const BoxContainer = styled(Grid)({
  backgroundColor: '#FFF',
  margin: '40px 0',
  boxShadow: '10px 14px 26px #D3E8FB',
  width: '88%',
  padding: '20px',

  '& h2': {
    fontFamily: 'sunrunSerif, serif',
    fontSize: '40px',
    lineHeight: '45px',
    fontWeight: '500',
  },
});

const LightCircle = styled('div')(({on}) => ({
  height: '35px',
  width: '35px',
  minWidth: '35px',
  borderRadius: '100%',
  backgroundColor: on ? '#00B085' : '#CCC',
}));

const LightContainer = styled('div')({
  color: '#272727',
  fontSize: '16px',
  lineHeight: '22px',
  margin: '20px 0',
  display: 'flex',
  alignItems: 'center',
  '& div:first-child': {
    marginRight: '20px',
  }
})

const Box = ({number, header, children}) => (
  <BoxContainer container>
    <Grid item md={1} xs={12}>
      <BulletPoint>{number}</BulletPoint>
    </Grid>
    <Grid item md={10} xs={12}>
      <h2>{header}</h2>
      {children}
    </Grid>
  </BoxContainer>
);

export default () => (
  <div>
    <ContentDivider>
      Keeping an eye on your panels
    </ContentDivider>
    <Container>
      <Box number="1" header="Log in to my.sunrun.com to check your production.">
        <Content>
          This is the easiest way to see how much energy you're producing.
        </Content>
        <Content>
          The closer you watch your production, the better sense you'll have 
          for what's normal, so check your numbers regularly.
        </Content>
      </Box>
      <Box number="2" header="Look for the light on the inverter.">
        <Content>
          Your inverter converts energy from your panels into electricity your home can use.
        </Content>
        <Content>
          Your inverter is probably in your garage or on the side of your house.
          Take a look and see what the light is telling you.
        </Content>
        <LightContainer>
          <LightCircle on="on" />
          <div>
            Green means your system is on and producing power.
          </div>
        </LightContainer>
        <LightContainer>
          <LightCircle />
          <div>
            The light may be off at night, but it should be on during the day.
          </div>
        </LightContainer>
      </Box>
    </Container>
  </div>
);
