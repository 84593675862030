import React from 'react';
import { Grid, Hidden, Bullet } from './UIComponents';
import { styled } from '@material-ui/styles';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  height: '100%',
});

const BulletContainer = styled(Grid)(({theme}) => ({
  margin: '0 5px',
  padding: '0 20px',

  [theme.breakpoints.down('xs')]: {
    margin: '30px 5px',
  }
}));

const BulletHeader = styled(Grid)({
  margin: '0',
});

const BulletTitle = styled('div')(({theme}) => ({
  fontSize: '20px',
  color: theme.colors.lightBlue,
  lineHeight: '25px',
  fontWeight: '500',
}));

const BulletDescription = styled('div')({
  fontSize: '16px',
  color: '#4A4A4A',
  lineHeight: '21px',
});

const DesignBullet = ({number, title, children}) => (
  <BulletContainer container>
    <BulletHeader container>
      <Hidden xsDown><Grid item sm={1}></Grid></Hidden>
      <Grid item xs={2} sm={1}>
        <Bullet>{number}</Bullet>
      </Grid>
      <Grid item xs={10}>
        <BulletTitle>{title}</BulletTitle>
      </Grid>
    </BulletHeader>
    <Hidden xsDown><Grid item sm={2}></Grid></Hidden>
    <Grid item xs={12} sm={10}>
      <BulletDescription>{children}</BulletDescription>
    </Grid>
  </BulletContainer>
);

export default ({systemDesignData}) => (
  <Container>
  {systemDesignData.map(({title, description}, i) => (
    <DesignBullet key={i} number={i+1} title={title}>{description}</DesignBullet>
  ))}
  </Container>
);
