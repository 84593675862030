import React from 'react';
import BaseLogin from '../shared/Login';

const Login = () => (
  <BaseLogin imageSrc="/login.png" imageAlt="Seated man using a laptop" header="Log in to mySunrun.com">
    See your solar energy production, find helpful information, and more.
  </BaseLogin>
);

export default Login;
