import React from 'react';
import { styled } from '@material-ui/styles';
import { Header, Grid } from './UiComponents';
import { HomeNavBar } from '../shared/PanelLayout';
import useFocusedComponent from '../shared/useFocusedComponent';

const HeaderContainer = styled('div')({
  color: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '25px',
  lineHeight: '35px',
  backgroundColor: '#0087FF',
  minHeight: '300px',
  textAlign: 'center',

  '& h1, div': {
    padding: '40px 20px',
  },

  '& h1': {
    paddingBottom: 0,
  }
})

const ImageBlock = styled('img')({
  width: '100%',
  height: 'auto',
})

const TextBlock = styled('div')(({accent}) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  fontSize: '20px',
  lineHeight: '28px',

  backgroundColor: accent === 'blue' ? '#001A3C' : '#FFF',
  color: accent === 'blue' ? '#FFF' : '#001A3C',

  '& h3': {
    margin: 0,
    textTransform: 'uppercase',
    fontSize: '30px',
    lineHeight: '28px',
    fontWeight: 'bold',
    marginBottom: '25px',
  },

  '& div': {
    width: '75%',
  }
}));

const GridItem = styled(Grid)(({theme, xsorder}) => ({
  maxHeight: '384px',

  '& > div': {
    height: '384px',
  },

  '& > img': {
    maxHeight: '384px',
    height: '100%',
  },

  [theme.breakpoints.down('xs')]: {
    order: xsorder || 0,
  }
}));

export default ({setNavbar}) => {
  const ref = useFocusedComponent(() => setNavbar(
    <HomeNavBar />
  ));

  return (
    <div ref={ref}>
      <HeaderContainer>
        <Header>Even more experts behind the scenes.</Header>
        <div>
          Every person you talk to has a room full of specialists backing them up.
        </div>
      </HeaderContainer>

      <Grid container>
        <GridItem item xs={12} sm={6} xsorder="1">
          <ImageBlock src="/team-handoff/expert-1.png" alt="Expert looking at computers" />
        </GridItem>
        <GridItem item xs={12} sm={6} xsorder="3">
          <ImageBlock src="/team-handoff/expert-2.png" alt="Experts collaborating at computer" />
        </GridItem>

        <GridItem item xs={12} sm={6} xsorder="2">
          <TextBlock accent="blue">
            <h3>Design Technicians</h3>
            <div>
              With satellite imagery and measurements taken by your site techinician's hand,
              our skilled designers customize your system to make sure you capture as much
              sunlight as you need.
            </div>
          </TextBlock>
        </GridItem>
        <GridItem item xs={12} sm={6} xsorder="4">
          <TextBlock accent="white">
            <h3>Permitting Team</h3>
            <div>
              Our permitting team handles the paperwork so you don't have to. They submit
              forms to the government, claim green tax incentives and rebates, and request
              connections to your local grid &mdash; all on your behalf.
            </div>
          </TextBlock>
        </GridItem>

        <GridItem item xs={12} sm={6} xsorder="5">
          <ImageBlock src="/team-handoff/expert-3.png" alt="Expert using a computer" />
        </GridItem>
        <GridItem item xs={12} sm={6} xsorder="7">
          <ImageBlock src="/team-handoff/expert-4.png" alt="Experts collaborating" />
        </GridItem>

        <GridItem item xs={12} sm={6} xsorder="6">
          <TextBlock accent="white">
            <h3>Monitoring Team</h3>
            <div>
              Our monitoring team reviews every home's production data for anything out of
              the ordinary, so we can identify issues and provide solutions before you even
              notice a problem.
            </div>
          </TextBlock>
        </GridItem>
        <GridItem item xs={12} sm={6} xsorder="8">
          <TextBlock accent="blue">
            <h3>Policy Team</h3>
            <div>
              If we can make going solar easier and more affordable, more people can enjoy
              clean energy. That's why our policy team partners with local and federal 
              officials to pass solar-friendly laws and regulations.
            </div>
          </TextBlock>
        </GridItem>
      </Grid>
    </div>
  );
}
